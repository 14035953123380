import React from "react";
import { Icon } from "@iconify/react";

function PageSelector(props) {
  return (
    <div className="pageSelector">
      <div
        className={
          "pagingButton" + (props.currentPage === 1 ? " disabled" : "")
        }
        onClick={() => props.HandlePageChange("first")}
      >
        <Icon icon="ph:skip-back" />
      </div>
      <div
        className={
          "pagingButton" + (props.currentPage === 1 ? " disabled" : "")
        }
        onClick={() => props.HandlePageChange("previous")}
      >
        <Icon icon="material-symbols:arrow-back" />
      </div>
      {props.pages.map((page, index) => (
        <div
          key={index}
          className={
            "pagingButton" +
            (page === props.currentPage && props.totalPages > 1
              ? " active"
              : "")
          }
          onClick={() => props.HandlePageChange(page)}
        >
          {page ?? 1}
        </div>
      ))}
      <div
        className={
          "pagingButton" +
          (props.currentPage === props.totalPages ? " disabled" : "")
        }
        onClick={() => props.HandlePageChange("next")}
      >
        <Icon icon="material-symbols:arrow-forward" />
      </div>
      {!props.hideLastButton ? (
        <div
          className={
            "pagingButton" +
            (props.currentPage === props.totalPages ? " disabled" : "")
          }
          onClick={() => props.HandlePageChange("last")}
        >
          <Icon icon="ph:skip-forward" />
        </div>
      ) : null}
    </div>
  );
}

export default PageSelector;
