import React, { useEffect } from "react";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import { UseUserContext } from "../../Contexts/UserContext";

function Logout(props) {
  const { Logout, returnTo } = UseUserContext();

  const { HandleLinkClick } = UseNavigationContext();

  useEffect(() => {
    Logout();
    HandleLinkClick(null, returnTo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

export default Logout;
