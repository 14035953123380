import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { UseCommentContext } from "../../../Contexts/CommentContext";

function CommentReply(props) {
  const [showReplyContainer, SetShowReplyContainer] = useState(false);
  const [replyText, SetReplyText] = useState("");
  const [replyPosting, SetReplyPosting] = useState(false);

  const { basicInfo, FullRefresh, CreateComment } = UseCommentContext();

  function ShowReplyContainer() {
    if (replyText.length > 0) {
      SetReplyText("");
    }
    SetShowReplyContainer(!showReplyContainer);
  }

  function ChangeReplyText(event) {
    SetReplyText(event.target.value);
  }

  async function ReplyToComment() {
    if (!props.LoginCheck("comment") || replyPosting) {
      return;
    }

    SetReplyPosting(true);
    let newComment = {
      postId: basicInfo.articleId,
      parentId: props.comment.id,
      content: replyText,
    };
    let response = await CreateComment(newComment);
    if (response.status === 200) {
      SetReplyPosting(false);
      // alert("Comment posted successfully! It will appear shortly");
      SetReplyText("");
      FullRefresh();
      ShowReplyContainer();
    } else {
      SetReplyPosting(false);
      alert("Error posting comment");
    }
  }

  return (
    <>
      <div className="commentReply" onClick={ShowReplyContainer}>
        {showReplyContainer ? "Cancel" : "Reply"}
      </div>
      {showReplyContainer ? (
        <div className="newCommentReplyForm">
          <textarea
            className="newCommentReplyFormInput"
            type="textarea"
            placeholder={"Reply to " + props.comment.username}
            value={replyText}
            onChange={ChangeReplyText}
          />
          <div
            className={
              !replyPosting
                ? "newCommentPostButton"
                : "newCommentPostButton postButtonDisabled"
            }
            onClick={replyPosting ? null : ReplyToComment}
          >
            <FontAwesomeIcon
              className="newCommentPostButtonIcon"
              icon={faPenToSquare}
            />
            <div className="newCommentPostButtonText">Reply</div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default CommentReply;
