import React from "react";

function AdminSidebar(props) {
  function ChangeSidebarOption(e, option) {
    if (e.button === 1 || e.metaKey || e.ctrlKey) {
      // console.log("Opening in new tab");
      return;
    }

    e.preventDefault();
    props.ChangeSidebarOption(e, option);
  }

  return (
    <div className="adminSidebar">
      <div className={"sidebarOption title"}>Admin</div>
      <a
        href="/admin/Videos"
        className={
          "sidebarOption" +
          (props.currentOption === "videos" ? " selected" : "")
        }
        onClick={(e) => {
          ChangeSidebarOption(e, "Videos");
        }}
      >
        Videos
      </a>
      <a
        href="/admin/Categories"
        className={
          "sidebarOption" +
          (props.currentOption === "categories" ? " selected" : "")
        }
        onClick={(e) => {
          ChangeSidebarOption(e, "Categories");
        }}
      >
        Categories
      </a>
      <a
        href="/admin/Users"
        className={
          "sidebarOption" + (props.currentOption === "users" ? " selected" : "")
        }
        onClick={(e) => {
          ChangeSidebarOption(e, "Users");
        }}
      >
        Users
      </a>
      <div className="sidebarOption refreshData" onClick={props.RefreshData}>
        Refresh Data
      </div>
    </div>
  );
}

export default AdminSidebar;
