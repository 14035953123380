import React, { useEffect, useState } from "react";
import FunkerTableItem from "./FunkerTableItem";

function FunkerTable(props) {
  const [sortedItems, SetSortedItems] = useState([]);
  const [paginatedItems, SetPaginatedItems] = useState([]);
  const [sortColumn, SetSortColumn] = useState(null);
  const [sortDescending, SetSortDescending] = useState(true);
  const [columns, SetColumns] = useState([]);
  const [columnWidths, SetColumnWidths] = useState([]);
  const [page, SetPage] = useState(null);

  function GetColumnWidths() {
    SetColumnWidths(
      props.columns
        .map((column) => {
          return column.GetWidth();
        })
        .join(" ")
    );
  }

  function ChangeSort(column) {
    if (column.header === sortColumn?.header) {
      SetSortDescending(!sortDescending);
    } else {
      SetSortColumn(column);
      SetSortDescending(column.sortType !== "string");
    }
  }

  function SetAutoWidths() {
    let columnsCopy = [...props.columns];
    for (let i = 0; i < columnsCopy.length; i++) {
      columnsCopy[i].SetAutoWidth(props.items);
    }
    SetColumns(columnsCopy);
  }

  function SortItems() {
    if (sortColumn == null) {
      for (let i = 0; i < props.columns.length; i++) {
        if (props.columns[i].sortable && props.columns[i].isDefaultSort) {
          SetSortColumn(props.columns[i]);
          return;
        }
      }
      SetSortedItems(props.items);
      return;
    }
    let sorted = [...props.items];
    sorted.sort((a, b) => {
      let aValue = sortColumn.GetValue(a, true);
      let bValue = sortColumn.GetValue(b, true);
      switch (sortColumn.sortType) {
        case "number":
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
          break;
        case "date":
          aValue = new Date(aValue);
          bValue = new Date(bValue);
          break;
        default:
          break;
      }
      if (aValue < bValue) {
        return sortDescending ? 1 : -1;
      }
      if (aValue > bValue) {
        return sortDescending ? -1 : 1;
      }
      return 0;
    });
    SetSortedItems(sorted);
  }

  function PagenateItems() {
    if (props.pageSize == null) {
      SetPaginatedItems(sortedItems);
      return;
    }
    let start = (page - 1) * props.pageSize;
    let end = start + props.pageSize;
    SetPaginatedItems(sortedItems.slice(start, end));
  }

  function GetSubtableItems(item) {
    if (
      props.subTableColumns == null ||
      (props.SubTableItemsFunction == null &&
        props.subTableItemsPathArray == null &&
        props.subTableItemsProperty == null)
    ) {
      return [];
    }
    if (props.SubTableItemsFunction) {
      return props.SubTableItemsFunction(item);
    }
    if (props.subTableItemsPathArray != null) {
      let value = item;
      for (let i = 0; i < props.subTableItemsPathArray.length; i++) {
        value = value[props.subTableItemsPathArray[i]];
      }
      return value;
    }
    return item[props.subTableItemsProperty];
  }

  function GetTotalRowData() {
    let totalRow = {};
    for (let i = 0; i < columns.length; i++) {
      totalRow[columns[i].property] = "";
      if (columns[i].totalRowHeader) {
        totalRow[columns[i].property] = "Total";
        continue;
      }
      if (columns[i].isTotaled) {
        let total = 0;
        for (let j = 0; j < props.items.length; j++) {
          total += columns[i].GetValue(props.items[j]);
        }
        totalRow[columns[i].property] = total;
      }
    }
    return totalRow;
  }

  function HandlePageChange(pageNumber) {
    switch (pageNumber) {
      case "first":
        SetPage(1);
        break;
      case "previous":
        if (page > 1) SetPage(page - 1);
        break;
      case "next":
        if (page < Math.ceil(props.items.length / props.pageSize))
          SetPage(page + 1);
        break;
      case "last":
        SetPage(Math.ceil(props.items.length / props.pageSize));
        break;
      default:
        SetPage(pageNumber);
        break;
    }
  }

  useEffect(() => {
    if (Array.isArray(props.columns)) {
      SetColumns(props.columns);
    }

    // eslint-disable-next-line
  }, [props.columns]);

  useEffect(() => {
    if (Array.isArray(props.items)) {
      SortItems();
    }

    // eslint-disable-next-line
  }, [props.items, sortColumn, sortDescending]);

  useEffect(() => {
    if (Array.isArray(props.items) && props.columns.length > 0) {
      SetAutoWidths();
    }

    // eslint-disable-next-line
  }, [props.items, props.columns]);

  useEffect(() => {
    GetColumnWidths();

    // eslint-disable-next-line
  }, [columns]);

  useEffect(() => {
    let defaultSortColumn = props.columns.find(
      (column) => column.isDefaultSort
    );
    if (defaultSortColumn?.sortType === "string") {
      SetSortDescending(false);
    }

    // eslint-disable-next-line
  }, [props.columns]);

  useEffect(() => {
    if (props.pageSize) {
      SetPage(1);
    }

    // eslint-disable-next-line
  }, [props.pageSize]);

  useEffect(() => {
    PagenateItems();

    // eslint-disable-next-line
  }, [sortedItems, page]);

  return (
    <div
      className={
        "funkerTable" +
        (props.isSubTable ? " funkerSubTable" : "") +
        (props.className ? " " + props.className : "") +
        (props.pageSize ? " hasPaging" : "")
      }
    >
      {!props.hideHeader ? (
        <FunkerTableItem
          columns={columns}
          isHeader={true}
          columnWidths={columnWidths}
          ChangeSort={ChangeSort}
          currentSortColumn={sortColumn}
          currentSortDescending={sortDescending}
          subTableColumns={props.subTableColumns}
        />
      ) : null}
      {sortedItems.length === 0 ? (
        <div className="funkerTableItem noItems">
          <div className="funkerTableItemCell noItems">
            {!props.loading ? "No items to display" : "Loading..."}
          </div>
        </div>
      ) : null}
      {paginatedItems.map((item, index) => {
        return (
          <FunkerTableItem
            columns={columns}
            item={item}
            key={index}
            columnWidths={columnWidths}
            subTableColumns={props.subTableColumns}
            subTableItems={GetSubtableItems(item)}
            highlighted={
              props.highlightedId != null
                ? props.highlightedId === item.id
                : false
            }
            onClickRow={props.onClickRow}
          />
        );
      })}
      {props.hasTotalRow ? (
        <FunkerTableItem
          columns={columns}
          item={GetTotalRowData()}
          columnWidths={columnWidths}
          isTotalRow={true}
          subTableColumns={props.subTableColumns}
        />
      ) : null}
      {page ? (
        <FunkerTableItem
          columns={columns}
          currentPage={page}
          totalPages={Math.ceil(props.items.length / props.pageSize)}
          columnWidths={columnWidths}
          isPagingRow={true}
          HandlePageChange={HandlePageChange}
        />
      ) : null}
    </div>
  );
}

export default FunkerTable;
