import React, { createContext, useContext } from "react";
import * as APIConstants from "../Constants/APIConstants";
import axios from "axios";

const CurrentContext = createContext();

function AdContext({ children }) {
  async function GetAds({
    typeId = null,
    amount = 1
  }) {
    let jsonRequest = [{
          typeId: typeId,
          amount: amount,
          pos: "na"
        }];
    let response = await axios
      .get(APIConstants.AD_BASE_URL, {
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          jsonRequest: JSON.stringify(jsonRequest)
        },
      })
      .catch ((error) => {
        return error;
      });
      
    return response;
  }

  return (
    <CurrentContext.Provider
      value={{ GetAds, }}>{children}</CurrentContext.Provider>
  );
}

export function UseAdContext() {
  const context = useContext(CurrentContext);
  if (!context) {
    throw new Error("useAdContext must be used within a Context");
  }
  return context;
}

export default AdContext;
