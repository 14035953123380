import React, { useState, useEffect, useRef } from "react";
import { UseVideoContext } from "../../Contexts/VideoContext";
import { UseUserContext } from "../../Contexts/UserContext";
import FunkerTableColumn from "../../Generic/FunkerTableColumn";
import FunkerTable from "../../Generic/FunkerTable";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import SearchItems from "./SearchItems";

function AdminList(props) {
  const [videos, SetVideos] = useState([]);
  const [categories, SetCategories] = useState([]);
  const [users, SetUsers] = useState([]);
  const [filteredList, SetFilteredList] = useState([]);
  const [search, SetSearch] = useState("");
  const [lastXMonths, SetLastXMonths] = useState(true);
  const loading = useRef([]);

  const { HandleLinkClick } = UseNavigationContext();
  const { GetVideos, GetCategories } = UseVideoContext();
  const { GetUsers } = UseUserContext();

  const videoColumns = [
    new FunkerTableColumn({
      header: "Title",
      property: "title",
      sortable: true,
      stretch: true,
      textAlignment: "left",
      applyAlignmentToHeader: true,
    }),
    new FunkerTableColumn({
      header: "Author",
      property: "author",
      sortable: true,
      textAlignment: "left",
      applyAlignmentToHeader: true,
      width: 15,
    }),
    new FunkerTableColumn({
      header: "Publication Date",
      ValueFunction: (item) => {
        return new Date(item.publicationDate).toLocaleString();
      },
      sortable: true,
      sortType: "date",
      isDefaultSort: true,
      textAlignment: "left",
      applyAlignmentToHeader: true,
      width: 18,
    }),
    new FunkerTableColumn({
      header: "Featured",
      ValueFunction: (item) => {
        return item.featured ? "*" : "";
      },
      sortable: true,
      textAlignment: "center",
      applyAlignmentToHeader: true,
      width: 7,
    }),
  ];

  const categoryColumns = [
    new FunkerTableColumn({
      header: "Name",
      property: "name",
      sortable: true,
      stretch: true,
      textAlignment: "left",
      applyAlignmentToHeader: true,
      isDefaultSort: true,
    }),
    new FunkerTableColumn({
      header: "Age Restricted",
      property: "ageRestricted",
      textAlignment: "left",
      applyAlignmentToHeader: true,
      width: 15,
    }),
    new FunkerTableColumn({
      header: "Password",
      property: "password",
      textAlignment: "left",
      applyAlignmentToHeader: true,
      width: 15,
    }),
  ];

  const userColumns = [
    new FunkerTableColumn({
      header: "Username",
      property: "username",
      sortable: true,
      width: 25,
      textAlignment: "left",
      applyAlignmentToHeader: true,
    }),
    new FunkerTableColumn({
      header: "Email",
      property: "email",
      sortable: true,
      stretch: true,
      textAlignment: "left",
      applyAlignmentToHeader: true,
    }),
    new FunkerTableColumn({
      header: "Email Status",
      property: "emailStatus",
      sortable: true,
      textAlignment: "left",
      applyAlignmentToHeader: true,
      width: 15,
      // hideOnMobile: true,
    }),
    new FunkerTableColumn({
      header: "Date Joined",
      ValueFunction: (item) => {
        return new Date(item.dateJoined).toLocaleString();
      },
      sortable: true,
      sortType: "date",
      textAlignment: "left",
      applyAlignmentToHeader: true,
      width: 15,
      isDefaultSort: true,
      // hideOnMobile: true,
    }),
    new FunkerTableColumn({
      header: "Last Login",
      ValueFunction: (item) => {
        return new Date(item.lastLogin).toLocaleString();
      },
      sortable: true,
      sortType: "date",
      textAlignment: "left",
      applyAlignmentToHeader: true,
      width: 15,
      // hideOnMobile: true,
    }),
    new FunkerTableColumn({
      header: "Admin",
      ValueFunction: (item) => {
        return item.isAdmin ? "Yes" : "";
      },
      sortable: true,
      textAlignment: "left",
      applyAlignmentToHeader: true,
      width: 8,
    }),
  ];

  function ChangeLastXMonths(e, value = null) {
    SetLastXMonths(value ?? !lastXMonths);
  }

  function ResetAllLists() {
    SetVideos([]);
    SetCategories([]);
    SetUsers([]);
    loading.current = [];
  }

  function GoToItem(e, item) {
    let id = item.id;
    if (props.type.toLowerCase() === "users") {
      id = item.username;
    }
    HandleLinkClick(e, "admin/" + props.type + "/" + id);
  }

  async function LoadVideos() {
    if (!loading.current.includes("videos")) {
      loading.current.push("videos");
    }
    let videos = await GetVideos({
      amount: null,
      sortBy: "date",
      allowUnpublished: true,
      allowPrivate: true,
      allowInvalid: true,
      includeAll: true,
      lastXMonths: lastXMonths,
    });
    if (videos.data instanceof Array) {
      SetVideos(videos.data);
      loading.current = loading.current.filter((item) => item !== "videos");
    }
  }

  async function LoadCategories() {
    if (!loading.current.includes("categories")) {
      loading.current.push("categories");
    }
    let newCategories = await GetCategories({});
    if (newCategories.data instanceof Array) {
      SetCategories(newCategories.data);
    }
    loading.current = loading.current.filter((item) => item !== "categories");
  }

  async function LoadUsers() {
    if (!loading.current.includes("users")) {
      loading.current.push("users");
    }
    let newUsers = await GetUsers({
      amount: null,
      minimal: true,
      lastXMonths: lastXMonths,
    });
    if (newUsers.data instanceof Array) {
      SetUsers(newUsers.data);
    }
    loading.current = loading.current.filter((item) => item !== "users");
  }

  function ChangeSearch(value) {
    SetSearch(value);
  }

  function FilterList() {
    let newList = [];
    switch (props.type.toLowerCase()) {
      case "videos":
        newList = videos.slice();
        break;
      case "categories":
        newList = categories.slice();
        break;
      case "users":
        newList = users.slice();
        break;
      default:
        break;
    }
    if (search.trim().length === 0) {
      SetFilteredList(newList);
      return;
    }
    switch (props.type.toLowerCase()) {
      case "videos":
        SetFilteredList(
          newList.filter((item) =>
            item.title.toLowerCase().includes(search.toLowerCase())
          )
        );
        break;
      case "categories":
        SetFilteredList(
          newList.filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
          )
        );
        break;
      case "users":
        SetFilteredList(
          newList.filter(
            (item) =>
              item.username.toLowerCase().includes(search.toLowerCase()) ||
              item.email.toLowerCase().includes(search.toLowerCase())
          )
        );
        break;
      default:
        break;
    }
  }

  function GetColumns() {
    switch (props.type.toLowerCase()) {
      case "videos":
        return videoColumns;
      case "categories":
        return categoryColumns;
      case "users":
        return userColumns;
      default:
        return [];
    }
  }

  function GetButtons() {
    switch (props.type.toLowerCase()) {
      case "videos":
        return [
          {
            text: "New Video",
            onClick: (e) => {
              HandleLinkClick(e, "admin/videos/add");
            },
          },
        ];
      case "categories":
        return [
          {
            text: "New Category",
            onClick: (e) => {
              HandleLinkClick(e, "admin/categories/add");
            },
          },
        ];
      case "users":
        return [];
      default:
        return [];
    }
  }

  useEffect(() => {
    ResetAllLists();
    switch (props.type.toLowerCase()) {
      case "videos":
        LoadVideos();
        break;
      case "categories":
        LoadCategories();
        break;
      case "users":
        LoadUsers();
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, lastXMonths]);

  useEffect(() => {
    FilterList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, videos, categories, users]);

  return (
    <div className="adminList moreReadable">
      <SearchItems
        search={search}
        ChangeSearch={ChangeSearch}
        buttons={GetButtons()}
        includeAll={lastXMonths}
        ToggleIncludeAll={ChangeLastXMonths}
        includeAllText={
          (props.type.toLowerCase() === "users"
            ? "Logged In During The "
            : "") + "Last 6 Months"
        }
        hideIncludeAll={props.type.toLowerCase() === "categories"}
      />
      <FunkerTable
        columns={GetColumns()}
        items={filteredList}
        pageSize={100}
        loading={loading.current?.includes(props.type)}
        onClickRow={GoToItem}
      />
    </div>
  );
}

export default AdminList;
