import React from "react";

function FormField(props) {
  //format the date to work with a date input field
  function getDate(date) {
    date = new Date(date);
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  }

  return (
    <>
      <div
        className={
          "formFieldLabel" +
          (props.formField.required ? " required" : "") +
          (props.formField.error ? " error" : "") +
          (props.formField.icon ? " withIcon" : "")
        }
      >
        {props.formField.label}
        {props.formField.icon ? props.formField.icon : ""}
      </div>
      {props.formField.type === "textarea" ? (
        <textarea
          className={
            "formFieldInput " +
            props.formField.type +
            (props.formField.required ? " required" : "") +
            (props.formField.error ? " error" : "") +
            (props.formField.disabled ? " disabled" : "")
          }
          maxLength={props.formField.maxLength ?? null}
          value={props.formField.value}
          onChange={(event) =>
            props.UpdateFormField(event.target.value, props.formField.apiName)
          }
        />
      ) : props.formField.type === "checkbox" ? (
        <input
          className={
            "formFieldInput " +
            props.formField.type +
            (props.formField.required ? " required" : "") +
            (props.formField.error ? " error" : "")
          }
          checked={props.formField.value}
          type={props.formField.type}
          onChange={(event) =>
            props.UpdateFormField(event.target.checked, props.formField.apiName)
          }
          disabled={props.formField.disabled ?? false}
        />
      ) : props.formField.type === "file" ? (
        <label className="formFieldInput file">
          <input
            type="file"
            accept={props.formField.accept}
            onChange={(event) =>
              props.UpdateFormField(
                event.target.files[0],
                props.formField.apiName
              )
            }
          />
        </label>
      ) : props.formField.type === "image" ? (
        <div className="imagePreview">
          {props.formField.value != null && props.formField.value !== "" && (
            <img src={props.formField.value} alt="Preview" loading="lazy" />
          )}
        </div>
      ) : props.formField.type === "multi-checkbox" ? (
        <div className="multiCheckbox">
          {props.formField.options.map((option, index) => (
            <label key={index}>
              <input
                type="checkbox"
                checked={props.formField.value.includes(option.value)}
                onChange={(event) => {
                  let newValues = [];
                  if (props.formField.multiSelect) {
                    newValues = [...props.formField.value];
                  } else {
                    newValues = [];
                  }
                  if (event.target.checked) {
                    newValues.push(option.value);
                  } else {
                    newValues = newValues.filter(
                      (value) => value !== option.value
                    );
                  }
                  props.UpdateFormField(newValues, props.formField.apiName);
                }}
                disabled={props.formField.disabled ?? false}
              />
              {option.label}
            </label>
          ))}
        </div>
      ) : props.formField.type === "date" ? (
        <input
          className={
            "formFieldInput " +
            props.formField.type +
            (props.formField.required ? " required" : "") +
            (props.formField.error ? " error" : "") +
            (props.formField.disabled ? " disabled" : "")
          }
          type={props.formField.type}
          value={getDate(props.formField.value)}
          onChange={(event) =>
            props.UpdateFormField(event.target.value, props.formField.apiName)
          }
        />
      ) : (
        <input
          className={
            "formFieldInput " +
            props.formField.type +
            (props.formField.required ? " required" : "") +
            (props.formField.error ? " error" : "") +
            (props.formField.disabled ? " disabled" : "")
          }
          type={props.formField.type}
          value={props.formField.value}
          maxLength={props.formField.maxLength ?? null}
          onChange={(event) =>
            props.UpdateFormField(event.target.value, props.formField.apiName)
          }
        />
      )}
    </>
  );
}

export default FormField;
