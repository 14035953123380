import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import * as APIConstants from "../../../Constants/APIConstants";
import * as Utilities from "../../../Constants/Utilities";
import { UseUserContext } from "../../../Contexts/UserContext";
import ChangePassword from "./ChangePassword";
import SetNewPassword from "./SetNewPassword";

function ResetPassword(props) {
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username");
  const token = searchParams.get("token");
  const [validToken, SetValidToken] = useState(null);
  const [processing, SetProcessing] = useState(true);
  const [usernameOrEmail, SetUsernameOrEmail] = useState("");
  const [resetPasswordSent, SetResetPasswordSent] = useState(false);
  const { user, loading } = UseUserContext();

  function HandleInput(e) {
    const { name, value } = e.target;
    switch (name) {
      case "username":
        SetUsernameOrEmail(value);
        break;
      default:
        break;
    }
  }

  async function HandleResetPassword() {
    const formData = new FormData();
    formData.append("username", usernameOrEmail);
    formData.append("baseUrl", window.location.origin);

    // eslint-disable-next-line no-unused-vars
    const result = await axios({
      method: "post",
      url: APIConstants.API_URL + "ResetPassword?code=" + APIConstants.API_CODE,
      data: formData,
    }).catch((error) => {
      if (error.response.data !== "User not found") {
        alert("An error occurred. Please try again later.");
        return;
      }
    });

    SetResetPasswordSent(true);
  }

  async function ValidateToken() {
    const result = await axios({
      method: "get",
      url: APIConstants.API_URL + "ResetPassword?code=" + APIConstants.API_CODE,
      params: { username: username, token: token },
    }).catch((error) => {
      SetValidToken(false);
      SetProcessing(false);
    });

    if (result?.data === "Token is valid") {
      SetValidToken(true);
      SetProcessing(false);
    }
  }

  useEffect(() => {
    if (username && token) {
      SetProcessing(true);
      ValidateToken();
      return;
    }
    SetProcessing(false);
    return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !processing && !loading ? (
    <div className="resetContainer">
      <div className="loginBox">
        <div className="loginLogoContainer">
          <img
            src="https://images.funker530.com/images/media/images/FunkerLogo.original.png"
            alt="Funker530 Logo"
            className="loginPageLogo"
          />
        </div>
        <div className="formContainer">
          {user && user !== "none" ? (
            <ChangePassword />
          ) : validToken === true ? (
            <SetNewPassword username={username} token={token} />
          ) : resetPasswordSent ? (
            <>
              <h1>Reset Confirmation</h1>
              <p className="resetPageText">
                If an account with that username or email exists, you will
                receive an email with instructions on how to reset your
                password.
              </p>
              <p className="resetPageText">
                If you don't receive an email, please make sure you have entered
                the email address or username you have registered with us and
                check your spam folder and Gmail "Promotions" folder.
              </p>
              <p className="resetPageText">
                Click <a href="/login">HERE</a> to return to the login page.
              </p>
            </>
          ) : validToken === false ? (
            <p className="resetPageText">
              The password reset link was invalid. Your token may have expired
              or have already been used. Click <a href="/resetpassword">HERE</a>{" "}
              to request a new password reset link.
            </p>
          ) : (
            <>
              <h1>Forgot Your Password?</h1>
              <div
                className="loginInputContainer"
                onKeyUp={(e) => Utilities.HandleEnter(e, HandleResetPassword)}
              >
                <input
                  type="text"
                  placeholder="Email or Username"
                  className="loginInput"
                  name="username"
                  onChange={HandleInput}
                />

                <div
                  className="loginButton funkerButton"
                  onClick={HandleResetPassword}
                >
                  RESET PASSWORD
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : null;
}

export default ResetPassword;
