import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  XIcon,
  TwitterShareButton,
} from "react-share";

function ShareModal(props) {
  function CloseShareModal() {
    props.ChangeShareModal();
  }

  let shareUrl =
    (window.location.origin.includes("localhost")
      ? "https://www.funker530.com"
      : window.location.origin) +
    "/" +
    props.pathArray.join("/");

  function CopyLink() {
    navigator.clipboard.writeText(shareUrl);
  }

  function StopPropogation(e) {
    e.stopPropagation();
  }

  return (
    <div className={"shareModal" + (props.showShareModal ? "" : " hidden")}>
      <div className="modalScreenMask" onClick={CloseShareModal}>
        <div className="modalContainer" onClick={StopPropogation}>
          <div className="modalHeader">
            <div className="modalName">Share</div>
            <div className="close" onClick={CloseShareModal}>
              <Icon icon="ic:outline-close" className="closeButton" />
            </div>
            <div className="shareTitle">{props?.title}</div>
          </div>
          <div className="shareLink">
            <input
              type="text"
              className="shareLinkText"
              disabled
              value={shareUrl}
            />
            <div className="copyLinkButton funkerButton" onClick={CopyLink}>
              Copy
            </div>
          </div>
          <div className="shareContainer">
            <EmailShareButton
              url={shareUrl}
              subject={props?.title}
              className="shareButton"
            >
              <EmailIcon round className="shareIcon" />
            </EmailShareButton>
            <FacebookShareButton url={shareUrl} className="shareButton">
              <FacebookIcon round className="shareIcon" />
            </FacebookShareButton>
            <TwitterShareButton
              url={shareUrl}
              title={props?.title}
              className="shareButton"
            >
              <XIcon round className="shareIcon" />
            </TwitterShareButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareModal;
