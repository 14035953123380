import React from "react";
// const IconSvg =
//   '<svg id="loadingSpinner" width="100%" height="100%" viewBox="0 0 218 218" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="109" cy="108.936" r="107.774" fill="#000000" fillOpacity="0.4" stroke="#F2F2F2" strokeOpacity="0.5" strokeWidth="2"/><circle cx="143.129" cy="170.008" r="19.4623" stroke="#F2F2F2" strokeOpacity="0.5" strokeWidth="3"/><circle cx="179.053" cy="108.936" r="19.4623" stroke="#F2F2F2" strokeOpacity="0.5" strokeWidth="3"/><circle cx="143.129" cy="47.8641" r="19.4623" stroke="#F2F2F2" strokeOpacity="0.5" strokeWidth="3"/><circle cx="109" cy="108.936" r="24.6472" stroke="#F2F2F2" strokeOpacity="0.5"/><path d="M91.8055 99.0085L109 89.0811L126.195 99.0085V118.863L109 128.791L91.8055 118.863V99.0085Z" stroke="#F2F2F2" strokeOpacity="0.5"/><circle cx="74.8719" cy="170.008" r="19.4623" stroke="#F2F2F2" strokeOpacity="0.5" strokeWidth="3"/><circle cx="38.9474" cy="108.936" r="19.4623" stroke="#F2F2F2" strokeOpacity="0.5" strokeWidth="3"/><circle cx="73.0758" cy="47.8641" r="19.4623" stroke="#F2F2F2" strokeOpacity="0.5" strokeWidth="3"/><path d="M83.853 89.1774C83.853 92.1535 81.4404 94.5661 78.4644 94.5661C75.4883 94.5661 73.0757 92.1535 73.0757 89.1774C73.0757 86.2013 75.4883 83.7887 78.4644 83.7887C81.4404 83.7887 83.853 86.2013 83.853 89.1774Z" fill="#F2F2F2" fillOpacity="0.5"/><path d="M83.353 89.1774C83.353 91.8773 81.1643 94.0661 78.4644 94.0661C75.7644 94.0661 73.5757 91.8773 73.5757 89.1774C73.5757 86.4774 75.7644 84.2887 78.4644 84.2887C81.1643 84.2887 83.353 86.4774 83.353 89.1774Z" stroke="#F2F2F2" strokeOpacity="0.5"/></svg>';

function LoadingIcon(props) {
  return (
    <div className={"loadingIcon playButtonSvg" + (props.spin ? " spin" : "")}>
      <svg
        id="loadingSpinner"
        width="100%"
        height="100%"
        viewBox="0 0 218 218"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="109"
          cy="108.936"
          r="107.774"
          fill="#000000"
          fillOpacity="0.4"
          stroke="#F2F2F2"
          strokeOpacity="0.5"
          strokeWidth="2"
        />
        <circle
          cx="143.129"
          cy="170.008"
          r="19.4623"
          stroke="#F2F2F2"
          strokeOpacity="0.5"
          strokeWidth="3"
        />
        <circle
          cx="179.053"
          cy="108.936"
          r="19.4623"
          stroke="#F2F2F2"
          strokeOpacity="0.5"
          strokeWidth="3"
        />
        <circle
          cx="143.129"
          cy="47.8641"
          r="19.4623"
          stroke="#F2F2F2"
          strokeOpacity="0.5"
          strokeWidth="3"
        />
        <circle
          cx="109"
          cy="108.936"
          r="24.6472"
          stroke="#F2F2F2"
          strokeOpacity="0.5"
        />
        <path
          d="M91.8055 99.0085L109 89.0811L126.195 99.0085V118.863L109 128.791L91.8055 118.863V99.0085Z"
          stroke="#F2F2F2"
          strokeOpacity="0.5"
        />
        <circle
          cx="74.8719"
          cy="170.008"
          r="19.4623"
          stroke="#F2F2F2"
          strokeOpacity="0.5"
          strokeWidth="3"
        />
        <circle
          cx="38.9474"
          cy="108.936"
          r="19.4623"
          stroke="#F2F2F2"
          strokeOpacity="0.5"
          strokeWidth="3"
        />
        <circle
          cx="73.0758"
          cy="47.8641"
          r="19.4623"
          stroke="#F2F2F2"
          strokeOpacity="0.5"
          strokeWidth="3"
        />
        <path
          d="M83.853 89.1774C83.853 92.1535 81.4404 94.5661 78.4644 94.5661C75.4883 94.5661 73.0757 92.1535 73.0757 89.1774C73.0757 86.2013 75.4883 83.7887 78.4644 83.7887C81.4404 83.7887 83.853 86.2013 83.853 89.1774Z"
          fill="#F2F2F2"
          fillOpacity="0.5"
        />
        <path
          d="M83.353 89.1774C83.353 91.8773 81.1643 94.0661 78.4644 94.0661C75.7644 94.0661 73.5757 91.8773 73.5757 89.1774C73.5757 86.4774 75.7644 84.2887 78.4644 84.2887C81.1643 84.2887 83.353 86.4774 83.353 89.1774Z"
          stroke="#F2F2F2"
          strokeOpacity="0.5"
        />
      </svg>
    </div>
  );
}

export default LoadingIcon;
