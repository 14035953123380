import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { UseUserContext } from "../../Contexts/UserContext";
import { UseNavigationContext } from "../../Contexts/NavigationContext";

function EmailConfirmation(props) {
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username");
  const token = searchParams.get("token");
  const [confirmationResult, SetConfirmationResult] = useState(null);
  const { ConfirmEmail, loading, returnTo, user } = UseUserContext();
  const { HandleLinkClick } = UseNavigationContext();

  async function HandleConfirmation() {
    let result = await ConfirmEmail(username, token);

    if (result?.status === 400) {
      alert(result.data);
      return;
    }

    if (
      result?.data === "Email already confirmed" ||
      result?.data === "Invalid token"
    ) {
      SetConfirmationResult(result.data);
      return;
    }
    SetConfirmationResult("Success.");
    setTimeout(() => {
      HandleLinkClick(null, returnTo);
    }, 3000);
  }

  async function HandleResendConfirmationEmail() {
    let result = await ConfirmEmail(user.username, "", true);

    if (result?.status === 400) {
      alert(result.data);
      return;
    }

    if (result?.data === "Email already confirmed") {
      SetConfirmationResult(result.data);
      return;
    }

    alert("Confirmation email sent.");
    HandleLinkClick(null, returnTo);
  }

  useEffect(() => {
    if (props.resend && user !== "none" && user !== null) {
      HandleResendConfirmationEmail();
      return;
    }
    if (username && token) {
      HandleConfirmation();
      return;
    } else {
      if (!loading) {
        HandleLinkClick(null, returnTo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    !loading &&
    (confirmationResult === null ? (
      <></>
    ) : (
      <>
        <div className="loginContainer">
          <div className="loginBox">
            <div className="loginLogoContainer">
              <img
                src="https://images.funker530.com/images/media/images/FunkerLogo.original.png"
                alt="Funker530 Logo"
                className="loginPageLogo"
              />
              <div className="confirmationMessage">
                {confirmationResult === "Email already confirmed" ? (
                  <>
                    <span>
                      Your email has already been confirmed.
                      {user === "none" ? (
                        <>
                          {" "}
                          Please log in <Link to={"/login"}>HERE</Link>.
                        </>
                      ) : null}
                    </span>
                  </>
                ) : confirmationResult === "Invalid token" ? (
                  <>
                    <span>
                      This confirmation link or token is invalid.{" "}
                      {user === "none" ? (
                        <>
                          Please log in <Link to={"/login"}>HERE</Link> and
                          request a new confirmation email.
                        </>
                      ) : (
                        <>
                          Please request a new confirmation email{" "}
                          <Link to={`/user/${user.username}`}>HERE</Link>.
                        </>
                      )}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      Your email has been confirmed. You'll soon be redirected
                      from this page.
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    ))
  );
}

export default EmailConfirmation;
