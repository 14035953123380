import React from "react";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import { Helmet } from "react-helmet-async";

function BaseSEO(props) {
  const { seoData } = UseNavigationContext();

  return (
    <>
      {seoData != null ? (
        <Helmet>
          <title>{seoData.title}</title>
          <meta name="description" content={seoData.description} />
          <meta name="keywords" content={seoData.keywords} />
          <meta name="author" content={seoData.author} />
          <meta name="twitter:card" content={seoData.twitterCard} />
          <meta name="twitter:site" content={seoData.twitterSite} />
          <meta name="twitter:creator" content={seoData.twitterCreator} />
          <meta name="twitter:title" content={seoData.twitterTitle} />
          <meta
            name="twitter:description"
            content={seoData.twitterDescription}
          />
          <meta name="twitter:image" content={seoData.twitterImage} />
          <meta property="og:type" content={seoData.ogtype} />
          <meta property="og:url" content={seoData.ogurl} />
          <meta property="og:title" content={seoData.ogtitle} />
          <meta property="og:description" content={seoData.ogdescription} />
          <meta property="og:image" content={seoData.ogimage} />
        </Helmet>
      ) : null}
    </>
  );
}

export default BaseSEO;
