import React, { useState, useRef, useEffect } from "react";
import VideoList from "../../Lists/VideoList";
import VideoListFilter from "./VideoListFilter";
import { UseVideoContext } from "../../Contexts/VideoContext";
import { UseUserContext } from "../../Contexts/UserContext";
import { useParams, useSearchParams } from "react-router-dom";
import PageSelector from "../../Generic/PageSelector";

function FullVideoList(props) {
  const [filter, SetFilter] = useState(null);
  const [listComplete, SetListComplete] = useState(false);
  const [resetToPage1, SetResetToPage1] = useState(false);
  const [page, SetPage] = useState(0);

  const { loadedCategories } = UseVideoContext();
  const { SetReturnToPath } = UseUserContext();
  const { categories, sort, days } = useParams();
  const [searchParams, SetSearchParams] = useSearchParams();

  function ScrollToTop() {
    if (listRef.current != null) {
      listRef.current.scrollTop = 0;
    }
  }

  function GetStartDate(days) {
    let date = new Date();
    let currentMonth = date.getMonth();
    switch (days) {
      case "this-week":
        date.setDate(date.getDate() - 7);
        break;
      case "this-month":
        date.setMonth(currentMonth - 1);
        if (currentMonth === date.getMonth()) {
          date.setDate(0);
        }
        break;
      case "this-year":
        date.setFullYear(date.getFullYear() - 1);
        if (currentMonth !== date.getMonth()) {
          date.setMonth(currentMonth + 1);
          date.setDate(0);
        }
        break;
      default:
        return null;
    }
    return date.toISOString();
  }

  function ProcessUrlChange() {
    ScrollToTop();
    let newFilter = {};
    newFilter.categories = categories?.split("+") ?? ["all"];
    newFilter.sortBy = sort ?? "date";
    newFilter.dateRange = days ?? "all-time";
    newFilter.startDate = GetStartDate(newFilter.dateRange);
    newFilter.search = searchParams.get("search");
    newFilter.amount = 40;
    SetFilter(newFilter);
    SetResetToPage1(true);
    SetReturnToPath(
      window.location.pathname + window.location.search,
      "Video List"
    );
  }

  function FindCategoryIds(categorySlugs) {
    let categoryIds = [];
    categorySlugs.forEach((slug) => {
      let category = loadedCategories.find((cat) => cat.slug === slug);
      if (category != null) {
        categoryIds.push(category.id);
      }
    });
    return categoryIds;
  }

  const listRef = useRef();

  function ChangeListCompleted(value) {
    SetListComplete(value);
  }

  function HandlePageChange(pageNumber) {
    switch (pageNumber) {
      case "first":
        SetPage(1);
        break;
      case "previous":
        if (page > 1) SetPage(page - 1);
        break;
      case "next":
        if (!listComplete) SetPage(page + 1);
        break;
      default:
        SetPage(pageNumber);
        break;
    }
  }

  useEffect(() => {
    if (resetToPage1) {
      if (page !== 0) {
        SetPage(0);
        return;
      }
      SetPage(1);
      SetResetToPage1(false);
    }

    let currentParams = {
      page: parseInt(searchParams.get("page") ?? "1"),
    };
    if (currentParams.page !== 0 && isNaN(currentParams.page)) {
      currentParams.page = 1;
    }

    if (searchParams.get("search") != null) {
      currentParams.search = searchParams.get("search");
    }
    if (currentParams.page < 1) {
      SetSearchParams({ ...currentParams, page: 1 });
      SetPage(1);
      SetListComplete(false);
      return;
    }
    if (currentParams.page !== page && page > 0) {
      SetSearchParams({ ...currentParams, page: page });
      return;
    }
    SetPage(currentParams.page);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, resetToPage1]);

  useEffect(() => {
    if (loadedCategories?.length > 0) {
      ProcessUrlChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, loadedCategories, searchParams]);

  return (
    <div className="fullVideoList" ref={listRef}>
      {filter != null ? (
        <>
          <VideoListFilter
            sortBy={filter.sortBy}
            categories={filter.categories}
            dateRange={filter.dateRange}
            page={page}
            HandlePageChange={HandlePageChange}
            noMorePages={listComplete}
          />
          <PageSelector
            currentPage={page}
            HandlePageChange={HandlePageChange}
            pages={[page]}
            totalPages={listComplete ? page : page + 1}
            hideLastButton={true}
          />
          <div className="searchingForLabel">
            {filter.search != null
              ? "Search - " + searchParams.get("search")
              : null}
          </div>
          <VideoList
            hideHeader="true"
            listId="fullVideoList"
            sortBy={filter.sortBy}
            startDate={filter.startDate}
            endDate={filter.endDate}
            search={filter.search}
            categories={FindCategoryIds(filter.categories)}
            channel={filter.channel}
            amount={filter.amount}
            loadsCompleted={page}
            ChangeListCompleted={ChangeListCompleted}
            listComplete={listComplete}
            isPaginated="true"
            adAtStart={true}
            numberOfAdsInMiddle={2}
            adAtEnd={true}
          />
          <PageSelector
            currentPage={page}
            HandlePageChange={HandlePageChange}
            pages={[page]}
            totalPages={listComplete ? page : page + 1}
            hideLastButton={true}
          />
        </>
      ) : null}
    </div>
  );
}

export default FullVideoList;
