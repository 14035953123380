import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      className: props.className || "",
      customError:
        props.customError || "An error happened during your request.",
      RunFunction: props.RunFunction || null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    let newStack = error.stack.toString();
    let newSplitStack = newStack.split("\n");

    return { hasError: true, errorInfo: newSplitStack };
  }

  render() {
    if (this.state.hasError) {
      if (this.state.RunFunction) {
        this.state.RunFunction();
      }
      return (
        <div className={"errorCatch " + this.state.className}>
          <div>{this.state.customError}</div>
        </div>
      );
    }

    return this.props.children;
  }
}
