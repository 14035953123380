import React, { memo } from "react";
import VideoListing from "./VideoListing";

function ListSection(props) {
  return (
    <div
      className={
        "listSection " +
        props.type +
        (props.scrollDirection === 1
          ? " left"
          : props.scrollDirection === -1
          ? " right"
          : "")
      }
      style={{
        gridTemplateColumns: `repeat(${props.listSettings.videoAmount}, 1fr)`,
      }}
    >
      {props.list.map((video, index) => (
        <VideoListing
          key={index}
          className={props.GetVideoListingClass()}
          video={video}
          biggerThumbnails={props.biggerThumbnails}
        />
      ))}
    </div>
  );
}

export default memo(ListSection);
