import React, { useRef, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { UseUserContext } from "../Contexts/UserContext";
import { UseNavigationContext } from "../Contexts/NavigationContext";
import Ad from "../Generic/Ad";
import ListSection from "./ListSection";

function VideoListSingleRow(props) {
  const [scrollDirection, SetScrollDirection] = useState(0);
  const [touchStart, SetTouchStart] = useState({
    x: 0,
    y: 0,
  });
  const [touchEnd, SetTouchEnd] = useState({
    x: 0,
    y: 0,
  });
  const [currentPosition, SetCurrentPosition] = useState(null);
  const currentPositionStep = 2;
  const [firstTouchedElement, SetFirstTouchedElement] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [rerenderTrigger, SetRerenderTrigger] = useState(false);
  const rerenderRef = useRef(false);
  const listInnerRef = useRef(null);
  const [adType, SetAdType] = useState("box");
  const swipeThreshold = 10;
  const [exceededThreshold, SetExceededThreshold] = useState(false);
  const videoArray =
    props.videos ?? Array(props.numberOfPlaceholders).fill(null);

  const listSettings = useRef({
    firstPositionIndex: 0,
    visibleVideos: [],
    nextVideos: [],
    previousVideos: [],
    videoAmount: 0,
    processedSections: <></>,
  });

  const { user } = UseUserContext();
  const { HandleLinkClick } = UseNavigationContext();

  function Scroll(right = true) {
    if (scrollDirection !== 0) {
      return;
    }
    SetCurrentPosition(
      touchEnd.x <= 2
        ? 1
        : Math.abs(
            (
              ((touchEnd.x - touchStart.x) /
                listInnerRef.current?.clientWidth) *
              100
            ).toFixed(0)
          )
    );
    SetScrollDirection(right ? 1 : -1);
  }
  function HandleTouchStart(e) {
    SetTouchStart({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY,
    });
    SetFirstTouchedElement(e.target);
  }

  function HandleTouchMove(e) {
    if (
      !exceededThreshold &&
      (Math.abs(touchStart.y - e.targetTouches[0].clientY) > swipeThreshold ||
        Math.abs(touchStart.x - e.targetTouches[0].clientX) > swipeThreshold)
    ) {
      SetExceededThreshold(true);
    }

    SetTouchEnd({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY,
    });
  }

  function HandleTouchEnd() {
    if (Math.abs(touchStart.y - touchEnd.y) > swipeThreshold) {
      SetTouchEnd({
        x: 0,
        y: 0,
      });
      SetTouchStart({
        x: 0,
        y: 0,
      });
      SetExceededThreshold(false);
      return;
    }
    if (touchStart.x - touchEnd.x > swipeThreshold) {
      Scroll(true);
      SetFirstTouchedElement(null);
      return;
    }

    if (touchStart.x - touchEnd.x < -swipeThreshold) {
      Scroll(false);
      SetFirstTouchedElement(null);
      return;
    }

    SetTouchEnd({
      x: 0,
      y: 0,
    });
    SetTouchStart({
      x: 0,
      y: 0,
    });
    if (exceededThreshold) {
      SetExceededThreshold(false);
      return;
    }
    firstTouchedElement?.click();
    SetFirstTouchedElement(null);
  }

  async function MakeVideoLists(
    videoArray,
    firstIndex = listSettings.current.firstPositionIndex
  ) {
    if (videoArray.length === 0) {
      return;
    }

    let tempMainList = [];
    let tempNextList = [];
    let tempPreviousList = [];

    for (let i = 0; i < listSettings.current.videoAmount; i++) {
      tempMainList.push(videoArray[(i + firstIndex) % videoArray.length]);
      tempNextList.push(
        videoArray[
          (i + firstIndex + listSettings.current.videoAmount) %
            videoArray.length
        ]
      );
      tempPreviousList.push(
        videoArray[
          (i +
            firstIndex -
            listSettings.current.videoAmount +
            videoArray.length) %
            videoArray.length
        ]
      );
    }

    listSettings.current.visibleVideos = tempMainList;
    listSettings.current.nextVideos = tempNextList;
    listSettings.current.previousVideos = tempPreviousList;
    listSettings.current.processedSections = (
      <>
        <ListSection
          type="main"
          list={
            scrollDirection === 0
              ? tempMainList
              : scrollDirection === 1
              ? tempNextList
              : tempPreviousList
          }
          listSettings={listSettings.current}
          GetVideoListingClass={GetVideoListingClass}
        />
        <ListSection
          type={"main" + (scrollDirection === 0 ? " inactive" : "")}
          list={tempMainList}
          listSettings={listSettings.current}
          GetVideoListingClass={GetVideoListingClass}
          scrollDirection={scrollDirection}
        />
        <ListSection
          type="next"
          list={tempNextList}
          listSettings={listSettings.current}
          GetVideoListingClass={GetVideoListingClass}
          scrollDirection={scrollDirection}
        />
        <ListSection
          type="previous"
          list={tempPreviousList}
          listSettings={listSettings.current}
          GetVideoListingClass={GetVideoListingClass}
          scrollDirection={scrollDirection}
        />
      </>
    );
    rerenderRef.current = !rerenderRef.current;
    SetRerenderTrigger(rerenderRef.current);
  }

  function GetVideoListingClass() {
    return "videoListing" + (props.biggerThumbnails ? " biggerThumbnail" : "");
  }

  function CalculateVideoAmount() {
    let mobileListingMinimum = 150;
    let desktopListingMinimum = 250;
    let switchToMobileAt = 768;
    let currentWindowWidth = listInnerRef.current?.clientWidth;

    let minimumToUse =
      currentWindowWidth <= switchToMobileAt
        ? mobileListingMinimum
        : desktopListingMinimum;

    if (mobileListingMinimum === minimumToUse) {
      SetAdType("banner");
    } else {
      SetAdType("box");
    }

    let videoAmountTemp = Math.floor(currentWindowWidth / minimumToUse);

    if (!user?.isPremium && currentWindowWidth > switchToMobileAt) {
      if (props.adAtStart) {
        videoAmountTemp -= 1;
      }
      if (props.adAtEnd) {
        videoAmountTemp -= 1;
      }
    }
    if (videoAmountTemp < 1) {
      videoAmountTemp += 1;
    }
    listSettings.current.videoAmount = videoAmountTemp;
    let timeout = setTimeout(() => {
      MakeVideoLists(videoArray);
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }

  function AnimationStep() {
    if (currentPosition <= 0 || currentPosition === 100) {
      SetScrollDirection(0);
      return;
    }
    let tempPosition = currentPosition + currentPositionStep;
    if (tempPosition < 0) {
      tempPosition = 0;
    }
    if (tempPosition > 100) {
      tempPosition = 100;
    }
    SetCurrentPosition(tempPosition);
  }

  useEffect(() => {
    let timeout = setTimeout(() => {
      AnimationStep();
    }, 10);
    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition]);

  useEffect(() => {
    if (listInnerRef.current == null) {
      return;
    }
    CalculateVideoAmount();

    let resizeObserver = new ResizeObserver(() => {
      CalculateVideoAmount();
    });

    resizeObserver.observe(listInnerRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listInnerRef, props.videos]);

  useEffect(() => {
    MakeVideoLists(props.videos);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.videos, listSettings.current.videoAmount]);

  useEffect(() => {
    MakeVideoLists(props.videos);
    if (scrollDirection !== 0) {
      if (scrollDirection === 1) {
        listSettings.current.firstPositionIndex =
          (listSettings.current.firstPositionIndex +
            listSettings.current.videoAmount) %
          props.videos.length;
      } else {
        listSettings.current.firstPositionIndex =
          (listSettings.current.firstPositionIndex -
            listSettings.current.videoAmount +
            props.videos.length) %
          props.videos.length;
      }
      return;
    } else {
      SetTouchEnd({
        x: 0,
        y: 0,
      });
      SetTouchStart({
        x: 0,
        y: 0,
      });
      SetCurrentPosition(-1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollDirection]);

  return (
    <>
      {!props.hideHeader ? (
        <div className="videoListTitleSection">
          {props.moreLink != null ? (
            <a
              href={props.moreLink}
              className="videoListTitle"
              onClick={(e) => {
                HandleLinkClick(e, props.moreLink);
              }}
            >
              {props.title}
            </a>
          ) : (
            <div className="videoListTitle">{props.title}</div>
          )}
          {props.moreLink != null ? (
            <a
              href={props.moreLink}
              className="videoListMore"
              onClick={(e) => {
                HandleLinkClick(e, props.moreLink);
              }}
            >
              MORE
            </a>
          ) : null}
        </div>
      ) : null}
      <div className={"videoListInner" + (props.singleRow ? " singleRow" : "")}>
        <div
          className={
            "videoListArrow" +
            (!props.singleRow
              ? " noArrow"
              : scrollDirection !== 0
              ? " inactive"
              : "")
          }
          onClick={() => Scroll(false)}
        >
          <Icon icon="fe:arrow-left" />
        </div>
        <div
          className="videoListGrid container singleRow"
          onTouchStart={HandleTouchStart}
          onTouchMove={HandleTouchMove}
          onTouchEnd={HandleTouchEnd}
          style={{
            gridTemplateColumns:
              (props.adAtStart &&
              !user?.isPremium &&
              listSettings.current.videoAmount > 1
                ? "1fr "
                : "") +
              (listSettings.current.videoAmount > 0
                ? listSettings.current.videoAmount
                : 1) +
              "fr" +
              (props.adAtEnd &&
              !user?.isPremium &&
              listSettings.current.videoAmount > 1
                ? " 1fr"
                : ""),
          }}
          ref={listInnerRef}
        >
          <div
            className={
              GetVideoListingClass() +
              ((props.adAtStart || (props.adAtEnd && adType === "banner")) &&
              !user?.isPremium &&
              !(
                listSettings.current.visibleVideos?.length === 0 &&
                !props.listComplete
              )
                ? " listAd outer beginning"
                : " hidden")
            }
          >
            {props.adAtStart || (props.adAtEnd && adType === "banner") ? (
              <Ad type={adType} />
            ) : null}
          </div>
          <div
            className="videoListGrid singleRow"
            style={{
              transform: `translateX(${
                currentPosition >= 0
                  ? (-scrollDirection * currentPosition) / 3
                  : touchEnd.x > 0
                  ? (
                      ((touchEnd.x - touchStart.x) /
                        3 /
                        listInnerRef.current?.clientWidth) *
                      100
                    ).toFixed(0)
                  : 0
              }%)`,
            }}
          >
            {listSettings.current.processedSections}
          </div>
          {props.adAtEnd && adType === "box" ? (
            <div
              className={
                GetVideoListingClass() +
                (props.singleRow &&
                props.adAtEnd &&
                !user?.isPremium &&
                !(
                  listSettings.current.visibleVideos?.length === 0 &&
                  !props.listComplete
                )
                  ? " listAd outer beginning"
                  : " hidden")
              }
            >
              <Ad type={adType} />
            </div>
          ) : null}
        </div>
        <div
          className={
            "videoListArrow" +
            (!props.singleRow
              ? " noArrow"
              : scrollDirection !== 0
              ? " inactive"
              : "")
          }
          onClick={() => Scroll(true)}
        >
          <Icon icon="fe:arrow-right" />
        </div>
      </div>
    </>
  );
}

export default VideoListSingleRow;
