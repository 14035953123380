import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareFacebook,
  faSquareTwitter,
  faSquareYoutube,
} from "@fortawesome/free-brands-svg-icons";

function Socials() {
  return (
    <div className="socials">
      <a href="https://www.facebook.com/FUNKER530/">
        <FontAwesomeIcon icon={faSquareFacebook} className="linkIcon" />
      </a>
      <a href="https://x.com/funkeractual">
        <FontAwesomeIcon icon={faSquareTwitter} className="linkIcon" />
      </a>
      <a href="https://www.youtube.com/user/FUNKER530">
        <FontAwesomeIcon icon={faSquareYoutube} className="linkIcon" />
      </a>
    </div>
  );
}

export default Socials;
