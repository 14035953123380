import { useState } from "react";
import VideoList from "./VideoList";

export default function VideoLists(props) {
  const [selectedList, setSelectedList] = useState(0);

  return (
    <div className={"videoLists" + (props.tabbed ? " tabbed" : "")}>
      {props.tabbed && (
        <div className="listSelectorHeader">
          {props.lists?.map((list, index) => (
            <div
              key={list.listId ?? index}
              onClick={() => setSelectedList(index)}
              className={`listSelector ${
                selectedList === index ? "active" : ""
              }`}
            >
              {list.title}
              <div className="underline"></div>
            </div>
          ))}
        </div>
      )}
      <div className="videoListBody">
        {props.lists?.map((list, index) => (
          <div
            className={props.tabbed && selectedList !== index ? "hideList" : ""}
            key={list.listId ?? index}
          >
            <VideoList
              title={props.tabbed ? "" : list.title}
              singleRow={props.singleRow}
              sortBy={list.sortBy}
              listId={list.listId}
              loadsCompleted={1}
              adAtStart={list.adAtStart || props.tabbed}
              adAtEnd={list.adAtEnd || props.tabbed}
              startDate={list.startDate}
              endDate={list.endDate}
              moreLink={list.moreLink}
              {...props}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
