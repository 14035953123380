import React from "react";
import FunkerPlayer from "./Player/FunkerPlayer";
import { Icon } from "@iconify/react";
import * as Utilities from "../Constants/Utilities";
import VideoInfo from "./VideoInfo";
import { UseUserContext } from "../Contexts/UserContext";
import { UseNavigationContext } from "../Contexts/NavigationContext";
import LoadingIcon from "./Player/LoadingIcon";
import Ad from "../Generic/Ad";

function VideoReel(props) {
  const { user, loading } = UseUserContext();
  const { HandleLinkClick } = UseNavigationContext();

  function GoToVideo(e, previous = false) {
    HandleLinkClick(
      e,
      "video/" + (previous ? props.previousVideo.slug : props.nextVideo.slug)
    );
  }

  return (
    <div
      className={
        "videoReel" +
        (props.biggerVideo ? " biggerVideo" : "") +
        (user?.isPremium ? " premium" : "")
      }
    >
      {props.previousVideo != null ? (
        <>
          <a
            href={"/video/" + props.previousVideo.slug}
            className="previewContainer previousVideo"
            onClick={(e) => {
              if (e.button === 1 || e.metaKey || e.ctrlKey) {
                return true;
              }

              e.preventDefault();
              GoToVideo(e, true);
            }}
          >
            <div className="previewImage">
              <div className="previewImageOverlay moreReadable">
                {props.previousVideo?.title}
                <div className="previewViewCount">
                  <Icon icon="ion:eye" />
                  <div className="viewText">
                    {props.previousVideo?.viewCount} -{" "}
                    {Utilities.GetPrettyDate(
                      props.previousVideo?.publicationDate
                    )}
                  </div>
                </div>
              </div>
              <img
                src={
                  props.previousVideo?.thumbnail?.file != null
                    ? "https://images.funker530.com/images/media/" +
                      props.previousVideo.thumbnail.file
                    : null
                }
                alt="Previous Video Thumbnail"
                loading="lazy"
              />
            </div>
          </a>
          {props.biggerVideo ? <Ad type="banner" /> : null}
        </>
      ) : (
        <div className="previewContainer previousVideo empty"></div>
      )}
      <div className="mainVideo">
        {props.mainVideo != null && !loading && user != null ? (
          <>
            <div className="funkerPlayerContainer">
              <FunkerPlayer
                video={props.mainVideo}
                user={user}
                allwaysMuted={props.allwaysMuted}
                randomVideos={props.randomVideos}
              />
            </div>
            {props.biggerVideo ? null : (
              <VideoInfo
                video={props.mainVideo}
                videoHeading={props.videoHeading}
                homePage={props.homePage}
              />
            )}
          </>
        ) : (
          <div className="funkerPlayerContainer">
            <div className="funkerPlayer" id="funkerPlayer">
              <LoadingIcon spin={true} />
            </div>
          </div>
        )}
      </div>
      {props.nextVideo != null ? (
        <>
          <a
            href={"/video/" + props.nextVideo.slug}
            className="previewContainer nextVideo"
            onClick={(e) => {
              if (e.button === 1 || e.metaKey || e.ctrlKey) {
                return true;
              }

              e.preventDefault();
              GoToVideo(e, false);
            }}
          >
            {/* <div className="verticalTextButton">NEXT</div> */}
            <div className="previewImage">
              <div className="previewImageOverlay moreReadable">
                {props.nextVideo?.title}
                <div className="previewViewCount">
                  <Icon icon="ion:eye" />
                  <div className="viewText">
                    {props.nextVideo?.viewCount} -{" "}
                    {Utilities.GetPrettyDate(props.nextVideo?.publicationDate)}
                  </div>
                </div>
              </div>
              <img
                src={
                  props.nextVideo?.thumbnail?.file != null
                    ? "https://images.funker530.com/images/media/" +
                      props.nextVideo.thumbnail.file
                    : null
                }
                alt="Next Video Thumbnail"
                loading="lazy"
              />
            </div>
          </a>
          {props.biggerVideo ? <Ad type="banner" /> : null}
        </>
      ) : (
        <div className="previewContainer nextVideo empty"></div>
      )}
      {props.biggerVideo ? (
        <VideoInfo
          video={props.mainVideo}
          biggerVideo={props.biggerVideo}
          randomVideos={props.randomVideos}
          showComments={true}
          biggerThumbnails={props.biggerThumbnails}
        />
      ) : null}
    </div>
  );
}

export default VideoReel;
