import React, { useEffect, useState } from "react";
import { UseVideoContext } from "../../Contexts/VideoContext";
import { UseNavigationContext } from "../../Contexts/NavigationContext";

function VideoListFilter(props) {
  const { loadedCategories } = UseVideoContext();
  const [filters, SetFilters] = useState();

  const { HandleLinkClick } = UseNavigationContext();

  const timeframes = [
    { value: "all-time", name: "All Time" },
    { value: "this-week", name: "This Week" },
    { value: "this-month", name: "This Month" },
    { value: "this-year", name: "This Year" },
  ];

  const sortOptions = [
    { value: "date", name: "Date" },
    { value: "views", name: "Views" },
  ];

  function ProcessPath() {
    let pathArray = window.location.pathname.split("/");
    let newFilter = {};
    newFilter.categories = pathArray[2].split("+") ?? ["all"];
    newFilter.dateRange = pathArray[3] ?? "all-time";
    newFilter.sortBy = pathArray[4] ?? "date";
    return newFilter;
  }

  function ChangeURLPart(part, value) {
    let newParts = filters;
    switch (part) {
      case "category":
        if (
          value === "all" ||
          (newParts.categories.length === 1 && newParts.categories[0] === value)
        ) {
          newParts.categories = ["all"];
        } else {
          if (
            newParts.categories.length > 0 &&
            newParts.categories[0] === "all"
          ) {
            newParts.categories = [];
          }
          if (newParts.categories.includes(value)) {
            newParts.categories = newParts.categories.filter(
              (cat) => cat !== value
            );
          } else {
            newParts.categories.push(value);
          }
        }
        break;
      case "sort":
        newParts.sortBy = value;
        break;
      case "range":
        newParts.dateRange = value;
        break;
      default:
        break;
    }
    HandleLinkClick(
      null,
      "videos/" +
        newParts.categories.join("+") +
        "/" +
        newParts.dateRange +
        "/" +
        newParts.sortBy +
        window.location.search,
      true
    );
  }

  useEffect(() => {
    SetFilters(ProcessPath());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return filters != null ? (
    <div className="videoFilter">
      <div className="filterContainer">
        <div className="filterTitle">Category:</div>
        <div className="filterSelect multi">
          <div
            className={
              "filterOption" +
              (filters.categories.includes("all") ? " selected" : "")
            }
            onClick={() => ChangeURLPart("category", "all")}
          >
            All
          </div>
          {loadedCategories.map((category, index) => (
            <div
              key={index}
              className={
                "filterOption" +
                (filters.categories.includes(category.slug) ? " selected" : "")
              }
              onClick={() => ChangeURLPart("category", category.slug)}
            >
              {category.name}
            </div>
          ))}
        </div>
      </div>
      <div className="filterContainer">
        <div className="filterTitle">Timeframe:</div>
        <div className="filterSelect multi">
          {timeframes.map((timeframe, index) => (
            <div
              key={index}
              className={
                "filterOption" +
                (filters.dateRange === timeframe.value ? " selected" : "")
              }
              onClick={() => ChangeURLPart("range", timeframe.value)}
            >
              {timeframe.name}
            </div>
          ))}
        </div>
      </div>
      <div className="filterContainer">
        <div className="filterTitle">Sort By:</div>
        <div className="filterSelect multi">
          {sortOptions.map((sortOption, index) => (
            <div
              key={index}
              className={
                "filterOption" +
                (filters.sortBy === sortOption.value ? " selected" : "")
              }
              onClick={() => ChangeURLPart("sort", sortOption.value)}
            >
              {sortOption.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
}

export default VideoListFilter;
