import React from "react";

function About() {
  return (
    <div className="about infoPage">
      <div className="infoPageContainer">
        <h1>About</h1>
        <h2>
          Funker530 was started as a hobby project on
          <a
            href="https://www.youtube.com/funker530"
            target="_blank"
            rel="noreferrer"
          >
            {" YouTube "}
          </a>
          by Funker, to show his family and friends some videos from his
          deployment as a light machine gunner to Kandahar, Afghanistan with the
          Canadian Forces on YouTube in 2008.
        </h2>
        <p>
          The channel began to grow through YouTube's algorithm, and more and
          more veterans began joining the community and submitting their
          footage. The YouTube channel soon became a place for veterans to share
          their experiences with the world, and help civilians and military
          families better understand what our troops go through on a deployment.
        </p>
        <p>
          It became apparent that there was a need for a veteran community
          online, so Funker launched a
          <a
            href="https://www.facebook.com/FUNKER530/"
            target="_blank"
            rel="noreferrer"
          >
            {" Facebook Page "}
          </a>
          which has grown to over 2 million members, where veterans can stay
          connected and share their opinions on world events with everyone.
        </p>
        <p>
          Funker530.com started as a simple blog for our veteran writers to
          share their opinions on global military events and provide analysis of
          combat footage from their own experiences, but has now evolved into a
          home for veterans and those who support our troops to stay informed
          and connected on all relevant topics.
        </p>
        <p>
          Funker also launched
          <a
            href="https://www.facebook.com/FunkerGaming"
            target="_blank"
            rel="noreferrer"
          >
            {" Funker Gaming "}
          </a>
          on Facebook in 2018, which gives veterans another way to stay
          connected in a positive community that centers around military games
          during daily live streams.
        </p>
        <p>
          The new Funker530.com offers a unique comment system for our users,
          where moderators can reward exceptional comments and users with
          comment awards, and free Funker merch from our StreamElements shop.
          The comment and user system is still in early beta, but is being
          developed to keep track of your comment points in a system that will
          allow users to redeem their positive and constructive engagement with
          the community for real world rewards.
        </p>
        <p>
          All branches of Funker are owned and operated by veterans, and always
          will be. Our mission is to give veterans a place to connect, and give
          honest and unbiased reports on global events. We do not consider
          ourselves diehard right or left wing, we only care about freedom,
          justice, honesty, and ensuring our brothers stay connected and have a
          voice during and after service.
        </p>
        <p>
          <b>
            For advertising and media inquiries, bug reports, article
            corrections, or for technical support regarding our mobile app
            please email
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:Admin@Funker530.com"
            >
              {" admin@funker530.com"}
            </a>
            .
          </b>
        </p>
      </div>
    </div>
  );
}

export default About;
