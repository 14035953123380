import React from "react";
import Comment from "./Comment/Comment";
import "react-tooltip/dist/react-tooltip.css";
import NewCommentForm from "./Form/NewCommentForm";
import { Tooltip } from "react-tooltip";
import { UseCommentContext } from "../Contexts/CommentContext";
import PageSelector from "../Generic/PageSelector";

function Comments() {
  const {
    parentComments,
    userComments,
    basicInfo,
    amountPerPage,
    page,
    totalPages,
    hideBottomPageSelector,
    HandlePageChange,
    FullRefresh,
    LoginCheck,
    GenerateCommentCountText,
  } = UseCommentContext();

  return (
    <div
      id="CommentSectionContainer"
      data-funkertheme="dark"
      className="moreReadable"
    >
      <Tooltip id="commentTooltip" />
      <NewCommentForm
        basicInfo={basicInfo}
        FullRefresh={FullRefresh}
        LoginCheck={LoginCheck}
      />
      {userComments.length > 0 ? (
        <>
          <div className="commentsCount">Your Parent Comments</div>
          <div className="commentsList">
            {userComments
              .filter((c) => c.parentId == null)
              .map((comment, index) => (
                <Comment
                  key={index}
                  comment={comment}
                  keySuffix="u"
                  parentLevel={-50}
                />
              ))}
          </div>
        </>
      ) : null}
      {/* <div className="betaNotice">
        <div className="betaNoticeTitle">Please Be Advised</div>
        <div className="betaNoticeText">
          You are currently on the Beta version of Funker530.com. Comments made
          here will NOT transfer to the main site but will remain once this
          version is officially released. Also, comments and activity could be
          up to 90 minutes behind the main site.
        </div>
      </div> */}
      <div id="commentsCountDiv" className="commentsCount">
        {GenerateCommentCountText()}
      </div>
      {amountPerPage > 0 ? (
        <PageSelector
          currentPage={page}
          totalPages={totalPages}
          HandlePageChange={HandlePageChange}
          pages={[page]}
        />
      ) : null}
      <div className="commentsList">
        {parentComments
          .slice((page - 1) * amountPerPage, page * amountPerPage)
          .map((comment, index) => (
            <Comment
              key={index}
              comment={comment}
              keySuffix=""
              parentLevel={0}
            />
          ))}
      </div>
      {!hideBottomPageSelector &&
      amountPerPage > 0 &&
      parentComments.length > 0 ? (
        <PageSelector
          currentPage={page}
          totalPages={totalPages}
          HandlePageChange={HandlePageChange}
          pages={[page]}
        />
      ) : null}
    </div>
  );
}

export default Comments;
