import React, { useEffect, useState } from "react";
import VideoReel from "../../Video/VideoReel";
import HomePageListDisplay from "./HomePageListDisplay";
import { UseVideoContext } from "../../Contexts/VideoContext";
import { UseUserContext } from "../../Contexts/UserContext";

function HomePage(props) {
  const [featuredVideo, SetFeaturedVideo] = useState(null);
  const [mostRecentVideo, SetMostRecentVideo] = useState(null);
  const [trendingRecentVideo, SetTrendingRecentVideo] = useState(null);

  let trendingStartDate = new Date();
  trendingStartDate.setDate(trendingStartDate.getDate() - 7);

  const { GetVideos } = UseVideoContext();
  const { SetReturnToPath } = UseUserContext();

  async function LoadMainVideos() {
    let response = await GetVideos({
      sortBy: "date",
      amount: 1,
      featured: true,
    });
    if (response.status === 200) {
      SetFeaturedVideo(response.data[0]);
    }
    let response2 = await GetVideos({
      sortBy: "date",
      amount: 1,
    });
    if (response2.status === 200) {
      SetMostRecentVideo(response2.data[0]);
    }
    let response3 = await GetVideos({
      sortBy: "views",
      amount: 1,
      startDate: trendingStartDate.toUTCString(),
      endDate: new Date().toUTCString(),
    });
    if (response3.status === 200) {
      SetTrendingRecentVideo(response3.data[0]);
    }
  }

  useEffect(() => {
    LoadMainVideos();
    SetReturnToPath("/", "Home");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="home mainContent">
      <VideoReel
        videoHeading="FEATURED VIDEO"
        mainVideo={featuredVideo}
        nextVideo={trendingRecentVideo}
        previousVideo={mostRecentVideo}
        homePage={true}
        allwaysMuted={true}
      />
      <HomePageListDisplay trendingStartDate={trendingStartDate} />
    </div>
  );
}

export default HomePage;
