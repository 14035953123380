import React, { useState } from "react";
import { UseNavigationContext } from "../Contexts/NavigationContext";

function NavMenu(props) {
  const [navMenuHoveredOver, SetNavMenuHoveredOver] = useState(false);
  const [submenuHoveredOver, SetSubmenuHoveredOver] = useState(null);

  const { HandleLinkClick } = UseNavigationContext();

  function CloseMenu() {
    SetNavMenuHoveredOver(false);
    SetSubmenuHoveredOver(null);
    props.CloseMenu();
  }

  function HandleMouseOver() {
    SetNavMenuHoveredOver(true);
  }

  function HandleMouseOut() {
    SetNavMenuHoveredOver(false);
  }

  return (
    <div className={"navMenuAnchor" + (props.submenu ? " submenu" : "")}>
      {(props.open || navMenuHoveredOver) && props.options?.length > 0 ? (
        <div
          className="navMenu"
          onMouseOver={HandleMouseOver}
          onMouseOut={HandleMouseOut}
        >
          <div className="navMenuItems">
            {props.options.map((option, index) => {
              return (
                <div className="navMenuWrapper" key={index}>
                  <a
                    href={option.link}
                    key={index}
                    className={
                      "navMenuItem" +
                      (props.selected === option.value ? " selected" : "")
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      HandleLinkClick(e, option.link);
                      CloseMenu();
                    }}
                    onMouseOver={() => SetSubmenuHoveredOver(option.value)}
                  >
                    {option.label}
                  </a>
                  <NavMenu
                    open={submenuHoveredOver === option.value}
                    options={option.submenuOptions}
                    submenu={true}
                    CloseMenu={CloseMenu}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default NavMenu;
