import React, { useState, useEffect } from "react";
import * as Utilities from "../../../Constants/Utilities";
import { UseNavigationContext } from "../../../Contexts/NavigationContext";
import { UseUserContext } from "../../../Contexts/UserContext";

function ChangePassword(props) {
  const [oldPassword, SetOldPassword] = useState("");
  const [password, SetPassword] = useState("");
  const [passwordConfirm, SetPasswordConfirm] = useState("");
  const [oldPasswordValid, SetOldPasswordValid] = useState(true);
  const [passwordValid, SetPasswordValid] = useState(true);
  const [passwordConfirmValid, SetPasswordConfirmValid] = useState(true);
  const [buttonDisabled, SetButtonDisabled] = useState(true);
  const { user, loading, ChangeUserPassword } = UseUserContext();

  const { HandleLinkClick } = UseNavigationContext();

  function HandleInput(e) {
    const { name, value } = e.target;
    switch (name) {
      case "password":
        SetPassword(value);
        break;
      case "passwordConfirm":
        SetPasswordConfirm(value);
        break;
      case "oldPassword":
        SetOldPassword(value);
        break;
      default:
        break;
    }
  }

  async function HandleChangePassword() {
    if (buttonDisabled) {
      return;
    }

    const result = await ChangeUserPassword(
      user.username,
      oldPassword,
      password
    ).catch((error) => {
      alert("An error occurred. Please try again later.");
      return null;
    });

    if (result.data === "Updated") {
      alert("Password updated successfully.");
      HandleLinkClick(null, "user/" + user.username);
    }
    if (result.data === "Invalid password") {
      alert("Invalid password.");
    } else {
      alert("An error occurred. Please try again later.");
      return;
    }
  }

  function PasswordsMatch() {
    return password === passwordConfirm;
  }

  useEffect(() => {
    if (password.length > 0) {
      SetPasswordValid(Utilities.ValidateField("password", password));
    }
    if (passwordConfirm.length > 0) {
      SetPasswordConfirmValid(
        Utilities.ValidateField("password", passwordConfirm)
      );
    }
    if (oldPassword.length > 0) {
      SetOldPasswordValid(oldPassword.length >= 8);
    }
  }, [password, passwordConfirm, oldPassword]);

  useEffect(() => {
    if (
      passwordValid &&
      passwordConfirmValid &&
      oldPasswordValid &&
      oldPassword.length > 0 &&
      password.length > 0 &&
      PasswordsMatch()
    ) {
      SetButtonDisabled(false);
    } else {
      SetButtonDisabled(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    passwordValid,
    passwordConfirmValid,
    password,
    oldPassword,
    passwordConfirm,
  ]);

  if (user === "none" || user == null) {
    HandleLinkClick(null, "login");
  }

  return (
    !loading && (
      <>
        <h1>Change Password</h1>
        <div
          className="loginInputContainer"
          onKeyUp={(e) => Utilities.HandleEnter(e, HandleChangePassword)}
        >
          <input
            type="password"
            placeholder="Old Password"
            className={oldPasswordValid ? "loginInput" : "loginInput invalid"}
            name="oldPassword"
            id="oldPassword"
            onChange={HandleInput}
          />
          <input
            type="password"
            placeholder="New Password"
            className={
              passwordValid &&
              (PasswordsMatch() || passwordConfirm.length === 0)
                ? "loginInput"
                : "loginInput invalid"
            }
            name="password"
            id="password"
            onChange={HandleInput}
          />
          <input
            type="password"
            placeholder="Confirm Password"
            className={
              (passwordConfirmValid && PasswordsMatch()) ||
              passwordConfirm.length === 0
                ? "loginInput"
                : "loginInput invalid"
            }
            name="passwordConfirm"
            id="passwordConfirm"
            onChange={HandleInput}
          />
          <div
            className={
              "loginButton funkerButton" +
              (buttonDisabled ? " disabledButton" : "")
            }
            onClick={HandleChangePassword}
          >
            CHANGE PASSWORD
          </div>
        </div>
        {!oldPasswordValid &&
          Utilities.GenerateTooltip(
            "#oldPassword",
            "custom",
            "Please enter your current password"
          )}
        {!passwordValid &&
          Utilities.GenerateTooltip("#password", "invalidPassword")}
        {!passwordConfirmValid &&
          Utilities.GenerateTooltip("#passwordConfirm", "invalidPassword")}
        {passwordValid &&
          passwordConfirmValid &&
          !PasswordsMatch() &&
          Utilities.GenerateTooltip("#password", "passwordsDoNotMatch")}
        {passwordValid &&
          passwordConfirmValid &&
          !PasswordsMatch() &&
          Utilities.GenerateTooltip("#passwordConfirm", "passwordsDoNotMatch")}
        {buttonDisabled &&
          Utilities.GenerateTooltip(
            ".funkerButton",
            "custom",
            "Please enter your current password and a valid new password"
          )}
      </>
    )
  );
}

export default ChangePassword;
