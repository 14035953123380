import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UseUserContext } from "../../Contexts/UserContext";
import { Link } from "react-router-dom";
import * as APIConstants from "../../Constants/APIConstants";
import { Icon } from "@iconify/react";
import VideoList from "../../Lists/VideoList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/sharp-solid-svg-icons";
import Markdown from "markdown-to-jsx";
import CommentContext from "../../Contexts/CommentContext";
import Comments from "../../Comments/Comments";

function UserPage(props) {
  const [userPublic, SetUserPublic] = useState(null);
  const [ownProfile, SetOwnProfile] = useState(false);
  const [showWarning, SetShowWarning] = useState(false);
  const { username } = useParams();
  const { user, GetUsers, ConfirmEmail, SetReturnToPath } = UseUserContext();

  async function GetUserByUsername() {
    const userPublic = await GetUsers({
      username: username,
    });
    if (userPublic?.data instanceof Array && userPublic.data.length >= 1) {
      SetUserPublic(userPublic.data[0]);
    }
  }

  function GetDateJoined() {
    let date = new Date(userPublic.dateJoined);
    return date.toLocaleDateString();
  }

  async function HandleResendConfirmationEmail() {
    let result = await ConfirmEmail(user.username, "", true);

    console.log(result);

    if (result?.status === 400) {
      alert(result.data);
      return;
    }

    alert("Confirmation email sent.");
  }

  useEffect(() => {
    if (user != null) {
      SetOwnProfile(username === user.username);
      SetShowWarning(
        username === user.username && !user.userProfile.emailConfirmed
      );
    }
    GetUserByUsername();
    SetReturnToPath("/user/" + username, "User Page");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, username]);

  return (
    <div className={"userPage" + (!showWarning ? "" : " showWarning")}>
      {userPublic != null ? (
        <>
          <div className="userProfileContainer">
            <div className="userProfileAvatar">
              <img
                src={
                  userPublic.avatar !== ""
                    ? APIConstants.IMAGES_BASE_URL + userPublic.avatar
                    : APIConstants.IMAGES_BASE_URL +
                      APIConstants.DEFAULT_AVATAR_IMAGE
                }
                alt={`Avatar for ${userPublic.username}`}
                loading="lazy"
              />
            </div>
            <div className="userProfileDetails">
              <div className="userProfileInfo name">
                <Icon icon="fa-user" className="icon" />
                {userPublic.username}
              </div>
              <div className="userProfileInfo">
                <Icon icon="fa-calendar" className="icon" />
                Member since {GetDateJoined()}
              </div>
            </div>
          </div>
          {ownProfile ? (
            <div className="userControlsContainer">
              <div className="userControls">
                <Link to={"/editprofile"} className="buttonLink">
                  <div className="userButton funkerButton">
                    <Icon icon="fa-edit" className="icon" />
                    Edit Profile
                  </div>
                </Link>
                <Link to="/resetpassword" className="buttonLink">
                  <div className="userButton funkerButton">
                    <Icon icon="fa-lock" className="icon" />
                    Password Reset
                  </div>
                </Link>
                {user?.isPremium ? (
                  <Link to="/premium" className="buttonLink">
                    <div className="userButton funkerButton">
                      <Icon
                        icon="fluent:premium-32-regular"
                        className="icon premium"
                      />
                      Subscription
                    </div>
                  </Link>
                ) : ownProfile ? (
                  <Link to="/premium" className="buttonLink">
                    <div className="userButton funkerButton">
                      <Icon
                        icon="fluent:premium-32-regular"
                        className="icon premium"
                      />
                      Go Premium
                    </div>
                  </Link>
                ) : null}
              </div>
            </div>
          ) : null}
          {showWarning && (
            <div className="userWarningContainer">
              <div className="userWarningHeader">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="warningIcon"
                />
                WARNING
              </div>
              <div className="userWarningText moreReadable">
                Your comments will not be public until you verify your email.
                Click the link below to resend the verification email. If you
                still do not receive the email, please check your spam or
                promotions folder, or confirm your email address is correct on
                your Edit Profile page.
              </div>
              <div
                className="userButton funkerButton"
                onClick={HandleResendConfirmationEmail}
              >
                <FontAwesomeIcon icon={faPaperPlane} className="icon" />
                RESEND EMAIL VERIFICATION LINK
              </div>
            </div>
          )}
          <div className="userStatsContainer">
            {userPublic.bio !== "" ? (
              <div className="userBioContainer">
                {/* <div className="userBioHeader">About {userPublic.username}</div> */}
                <div className="userBioText">{userPublic.bio}</div>
              </div>
            ) : null}
            {ownProfile && userPublic.authorInfo != null ? (
              <div className="userBioContainer">
                <div className="userBioHeader">Author Info</div>
                <div className="userBioText">
                  <Markdown>{userPublic.authorInfo}</Markdown>
                </div>
              </div>
            ) : null}
          </div>
          <CommentContext
            username={userPublic.username}
            amountPerPage={5}
            totalAmount={100}
            allOneLevel={true}
            hideBottomPageSelector={true}
            sectionTitle="Most Recent Comments"
            sortType="date"
          >
            <Comments />
          </CommentContext>
          <CommentContext
            username={userPublic.username}
            amountPerPage={5}
            totalAmount={100}
            allOneLevel={true}
            hideBottomPageSelector={true}
            sectionTitle="Highest Rated Comments"
            sortType="score"
          >
            <Comments />
          </CommentContext>
          {userPublic.favoriteVideos != null &&
          userPublic.favoriteVideos.length > 0 ? (
            <div className="userFavoriteVideosSection">
              <VideoList
                title={
                  "Favorite Videos" +
                  (ownProfile
                    ? userPublic.likedPublic
                      ? " (Public)"
                      : " (Private)"
                    : "")
                }
                videoIds={userPublic.favoriteVideos}
                loadsCompleted={1}
              />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default UserPage;
