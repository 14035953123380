import React, { useState } from "react";
import FooterMenu from "./FooterMenu";
import Socials from "./Socials";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import { Icon } from "@iconify/react/dist/iconify.js";

function Footer() {
  const { HandleLinkClick } = UseNavigationContext();

  const [hidden, setHidden] = useState(true);

  function ToggleHidden(show) {
    setHidden(!hidden);
  }

  return (
    <div className="footerContainer">
      <div className="footerTabAnchor">
        <div className="footerTab" onClick={ToggleHidden}>
          <Icon
            icon="ep:arrow-down-bold"
            className={"tabButton" + (hidden ? " hidden" : "")}
          />
          <Icon
            icon="ep:arrow-up-bold"
            className={"tabButton" + (hidden ? "" : " hidden")}
          />
        </div>
      </div>
      <div className={"footer" + (hidden ? " hidden" : "")}>
        <a
          href="/"
          className="copyright"
          onClick={(e) => {
            HandleLinkClick(e, "");
          }}
        >
          © <span className="red">FUNKER</span>
          530
        </a>
        <FooterMenu />
        <Socials />
      </div>
    </div>
  );
}

export default Footer;
