import React from "react";

function ContactUs() {
  return (
    <div className="contactUs infoPage">
      <div className="infoPageContainer">
        <h1>Contact Us</h1>
        <p>
          For advertising inquires, bug reports, DMCA claims, or to report a
          typo or incorrect information in a story, please email our admin team
          at
          <a
            href="mailto:Admin@Funker530.com"
            target="_blank"
            rel="noreferrer"
          >
            
            {" admin@funker530.com"}
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default ContactUs;
