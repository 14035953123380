export const AD_BASE_URL =
  "https://green.funker530.com/api/GetAds?code=sMYsoM1YowbUGryzG47fyGhv7JQ8GFm85SottVJJg3/AhtnBec8nzg==";
export const API_URL =
  process.env.API_LOCATION === "local"
    ? "http://localhost:7071/api/"
    : "https://funker530-fnc.azurewebsites.net/api/";
// export const API_URL = "http://localhost:7071/api/";
export const API_CODE =
  "sL3mjD-c0BJdI9b9h4s7WhIPU8ca9p6h3yiLyFczS-I9AzFupvbo9g==";
export const API_URL_COMMENTS =
  "https://funker530function.azurewebsites.net/api/";
export const IMAGES_BASE_URL = "https://images.funker530.com/images/media/";
export const DEFAULT_AVATAR_IMAGE = "anon.png";
export const MAIN_SITE_URL = "https://funker530.com";
