import React, { useState, useEffect } from "react";
import axios from "axios";
import * as APIConstants from "../../../Constants/APIConstants";
import * as Utilities from "../../../Constants/Utilities";

function SetNewPassword(props) {
  const [password, SetPassword] = useState("");
  const [passwordConfirm, SetPasswordConfirm] = useState("");
  const [passwordValid, SetPasswordValid] = useState(true);
  const [passwordConfirmValid, SetPasswordConfirmValid] = useState(true);
  const [buttonDisabled, SetButtonDisabled] = useState(true);
  const [passwordChangeResult, SetPasswordChangeResult] = useState(null);

  function HandleInput(e) {
    const { name, value } = e.target;
    switch (name) {
      case "password":
        SetPassword(value);
        break;
      case "passwordConfirm":
        SetPasswordConfirm(value);
        break;
      default:
        break;
    }
  }

  async function ChangePassword() {
    if (buttonDisabled) {
      return;
    }
    const formData = new FormData();
    formData.append("username", props.username);
    formData.append("newPassword", password);
    formData.append("token", props.token);

    const result = await axios({
      method: "post",
      url:
        APIConstants.API_URL + "ChangePassword?code=" + APIConstants.API_CODE,
      data: formData,
    }).catch((error) => {
      alert("An error occurred. Please try again later.");
      return;
    });

    SetPasswordChangeResult(result.data);
  }

  useEffect(() => {
    if (password.length > 0) {
      SetPasswordValid(Utilities.ValidateField("password", password));
    }
    if (passwordConfirm.length > 0) {
      // console.log("Validating password confirm");
      SetPasswordConfirmValid(
        Utilities.ValidateField("password", passwordConfirm)
      );
    }
    if (
      password.length > 0 &&
      passwordConfirm.length > 0 &&
      passwordValid &&
      passwordConfirmValid &&
      password === passwordConfirm
    ) {
      SetButtonDisabled(false);
    } else {
      SetButtonDisabled(true);
    }
  }, [password, passwordConfirm, passwordValid, passwordConfirmValid]);

  if (passwordChangeResult != null) {
    if (passwordChangeResult !== "Updated") {
      return (
        <p className="resetPageText">
          The password reset link was invalid. Your token may have expired or
          have already been used. Click <a href="/resetpassword">HERE</a> to
          request a new password reset link.
        </p>
      );
    }

    return (
      <>
        <h1>Change Password Confirmation</h1>
        <p className="resetPageText">
          Your password has been succesfully changed. You can now click{" "}
          <a href="/login">HERE</a> to log in.
        </p>
      </>
    );
  }

  return (
    <>
      <h1>Change Password</h1>
      <div className="loginInputContainer">
        <input
          type="password"
          placeholder="New Password"
          className={
            passwordValid &&
            (passwordConfirm.length === 0 || passwordConfirm === password)
              ? "loginInput"
              : "loginInput invalid"
          }
          name="password"
          onChange={HandleInput}
          id="password"
        />
        <input
          type="password"
          placeholder="Confirm Password"
          className={
            passwordConfirmValid &&
            (passwordConfirm.length === 0 || passwordConfirm === password)
              ? "loginInput"
              : "loginInput invalid"
          }
          name="passwordConfirm"
          onChange={HandleInput}
          id="passwordConfirm"
        />
        <div
          className={
            "loginButton funkerButton" +
            (buttonDisabled ? " disabledButton" : "")
          }
          onClick={ChangePassword}
        >
          CHANGE PASSWORD
        </div>
      </div>
      {!passwordValid &&
        Utilities.GenerateTooltip("#password", "invalidPassword")}
      {!passwordConfirmValid &&
        Utilities.GenerateTooltip("#passwordConfirm", "invalidPassword")}
      {passwordValid &&
        passwordConfirmValid &&
        password !== passwordConfirm &&
        Utilities.GenerateTooltip("passwordConfirm", "passwordsDoNotMatch")}
      {buttonDisabled &&
        Utilities.GenerateTooltip(
          ".funkerButton",
          "custom",
          "Please enter valid, matching passwords"
        )}
    </>
  );
}

export default SetNewPassword;
