import { Tooltip } from "react-tooltip";

export function GetPrettyDate(date, short = false) {
  if (date == null || date === "") return "Unknown";
  if (date instanceof Date === false) {
    date = new Date(date);
  }
  // displays how long ago the date was
  let diff = new Date() - date;
  let seconds = Math.floor(diff / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  let weeks = Math.floor(days / 7);
  let months = Math.floor(weeks / 4);
  let years = Math.floor(months / 12);

  if (seconds < 60) return short ? "*" : "Just now";
  if (minutes < 60) return short ? minutes + "m" : minutes + " min ago";
  if (hours < 24)
    return short
      ? hours + "h"
      : hours + " hour" + (hours > 1 ? "s" : "") + " ago";
  if (days < 7)
    return short ? days + "d" : days + " day" + (days > 1 ? "s" : "") + " ago";
  if (weeks < 4)
    return short
      ? weeks + "w"
      : weeks + " week" + (weeks > 1 ? "s" : "") + " ago";
  if (months < 12)
    return short
      ? months + "m"
      : months + " month" + (months > 1 ? "s" : "") + " ago";
  return short
    ? years + "y"
    : years + " year" + (years > 1 ? "s" : "") + " ago";
}

export function GetPrettyNumber(number) {
  if (number < 1000) return +number;
  if (number < 1000000) return Math.floor(number / 1000) + "K";
  if (number < 1000000000) return Math.floor(number / 1000000) + "M";
  return Math.floor(number / 1000000000) + "B";
}

export function ValidateField(Type, Value) {
  switch (Type) {
    case "email":
      return Value.length > 0 && Value.includes("@") && Value.includes(".");
    case "password":
      const length = Value.length;
      const lengthValid = length >= 8;
      const hasNumber = length > 0 && /\d/.test(Value);
      const hasUppercase = length > 0 && /[A-Z]/.test(Value);
      const hasLowercase = length > 0 && /[a-z]/.test(Value);
      const hasSpecialCharacter =
        length > 0 && /[-+_!@#$%^&*.,/\\?]/.test(Value);
      console.log(
        lengthValid,
        hasNumber,
        hasUppercase,
        hasLowercase,
        hasSpecialCharacter
      );
      return {
        lengthValid,
        hasNumber,
        hasUppercase,
        hasLowercase,
        hasSpecialCharacter,
      };
    default:
      return false;
  }
}

export function GenerateTooltip(
  target,
  type = "",
  message = "",
  place = "right",
  effect = "solid",
  validationFields = {}
) {
  switch (type) {
    case "invalidPassword":
      return (
        <Tooltip anchorSelect={target} place="right" effect="solid">
          Password must meet the following criteria:
          <span className={validationFields.lengthValid ? "" : "invalidField"}>
            - At Least 8 Characters
          </span>
          <span className={validationFields.hasNumber ? "" : "invalidField"}>
            - One Number
          </span>
          <span className={validationFields.hasUppercase ? "" : "invalidField"}>
            - One Uppercase Letter
          </span>
          <span className={validationFields.hasLowercase ? "" : "invalidField"}>
            - One Lowercase Letter
          </span>
          <span
            className={
              validationFields.hasSpecialCharacter ? "" : "invalidField"
            }
          >
            - One Special Character from the list below:
          </span>
          <span
            className={
              validationFields.hasSpecialCharacter ? "" : "invalidField"
            }
          >
            +-_!@#$%^&*.,/\?
          </span>
        </Tooltip>
      );
    case "passwordsDoNotMatch":
      return (
        <>
          <Tooltip anchorSelect={target} place={place} effect={effect}>
            Passwords do not match.
          </Tooltip>
        </>
      );
    case "invalidEmail":
      return (
        <Tooltip anchorSelect={target} place={place} effect={effect}>
          Please enter a valid email address.
        </Tooltip>
      );
    case "custom":
      return (
        <Tooltip anchorSelect={target} place={place} effect={effect}>
          {message}
        </Tooltip>
      );
    default:
      return (
        <Tooltip anchorSelect={target} place={place} effect={effect}>
          An error occurred. Please try again later.
        </Tooltip>
      );
  }
}

export function HandleEnter(e, callback) {
  if (e.key === "Enter") {
    callback();
  }
}

export function HandleLinkClick(e, callback, ...args) {
  if (e.button === 1 || e.metaKey || e.ctrlKey) {
    return true;
  }

  e.preventDefault();
  callback(...args);
}

export async function Sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
