import React, { useEffect, useState } from "react";
import { UseUserContext } from "../../Contexts/UserContext";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGem } from "@fortawesome/pro-light-svg-icons";
import { faPro } from "@fortawesome/pro-solid-svg-icons";
import { faDollarCircle } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";
import * as APIConstants from "../../Constants/APIConstants";
import Median from "median-js-bridge";

function GoPremium(props) {
  const { HandleLinkClick } = UseNavigationContext();
  const [deviceInfo, SetDeviceInfo] = useState();
  const { user, loading } = UseUserContext();
  const [processing, SetProcessing] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [purchasesData, SetPurchasesData] = useState();

  async function HandleCheckout() {
    if (processing) {
      return;
    }
    SetProcessing(true);

    if (deviceInfo?.platform?.toLowerCase() === "ios") {
      HandleIosCheckout();
      return;
    }
    let formData = new FormData();
    formData.append("userId", user.id);
    formData.append("baseUrl", window.location.origin);
    let result = await axios.post(
      APIConstants.API_URL + "UpgradeToPremium?code=" + APIConstants.API_CODE,
      formData
    );

    SetProcessing(false);
    window.location.href = result.data;
  }

  async function HandleIosCheckout() {
    let purchases = await Median.iap.info();
    let purchase = purchases?.inAppPurchases?.products[0]?.productID;

    if (!purchase) {
      SetProcessing(false);
      return;
    }
    // eslint-disable-next-line no-unused-vars
    let result = await Median.iap.purchase({ productID: purchase });
    SetProcessing(false);
  }

  async function HandleManageSubscription() {
    let formData = new FormData();
    formData.append("userId", user.id);
    formData.append("baseUrl", window.location.origin);
    let result = await axios.post(
      APIConstants.API_URL +
        "UpgradeToPremium/ManageSubscription?code=" +
        APIConstants.API_CODE,
      formData
    );

    window.location.href = result.data;
  }

  async function HandleRestorePurchases() {
    await Median.iap.restore();
    alert(
      "Restore requested. If you have active premium it will be restored shortly."
    );
  }

  async function GetDeviceInfo() {
    let info = await Median.deviceInfo();
    let purchaseInfo = await Median.iap.purchases();
    SetDeviceInfo(info);
    SetPurchasesData(purchaseInfo);
  }

  useEffect(() => {
    if (!loading && (user === null || user === "none")) {
      HandleLinkClick(null, "login");
      return;
    }

    if (!loading && user?.isPremium) {
      HandleManageSubscription();
    }

    GetDeviceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  return (
    !loading &&
    !user?.isPremium && (
      <div className="goPremium mainContent">
        <div className="goPremiumHero">
          <img
            src="https://images.funker530.com/images/media/images/Funker_Premium_Logo.max-800x600.png"
            alt=""
            className="goPremiumLogo"
          />
          <div className="goPremiumHeading">
            Funker530 Premium - Watch Ad Free On All Devices
          </div>
          <div className="goPremiumSubHeading">
            Only $4.99 per month • Cancel anytime
          </div>
          <div
            className="funkerButton goPremiumButton"
            onClick={HandleCheckout}
          >
            Upgrade Now
          </div>
          {deviceInfo?.platform?.toLowerCase() === "ios" && (
            <div
              className="funkerButton goPremiumButton"
              onClick={HandleRestorePurchases}
            >
              Restore Purchases
            </div>
          )}
        </div>
        <div className="goPremiumPerks">
          <div className="goPremiumPerk">
            <div className="goPremiumPerkIcon">
              <FontAwesomeIcon icon={faPro} className="icon premium" />
            </div>
            <div className="goPremiumPerkDescription">
              Exclusive Pro Comment Badge
            </div>
          </div>
          <div className="goPremiumPerk">
            <div className="goPremiumPerkIcon">
              <FontAwesomeIcon icon={faGem} className="icon premium" />
            </div>
            <div className="goPremiumPerkDescription">
              Get a Golden Comment Avatar Halo
            </div>
          </div>
          <div className="goPremiumPerk">
            <div className="goPremiumPerkIcon">
              <FontAwesomeIcon icon={faDollarCircle} className="icon premium" />
            </div>
            <div className="goPremiumPerkDescription">
              Support Funker530 Directly
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default GoPremium;
