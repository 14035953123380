import React from "react";
import PageLink from "../PageLink";

function FooterMenu() {
  return (
    <div className="footerMenu">
      <div className="navigation">
        <PageLink
          text="About"
          navigateTo="about"
          CloseMenu={() => {}}
          dontHide={true}
        ></PageLink>
        <PageLink
          text="Contact Us"
          navigateTo="contact-us"
          CloseMenu={() => {}}
          dontHide={true}
        ></PageLink>
        <PageLink
          text="Privacy Policy"
          navigateTo="privacy-policy"
          CloseMenu={() => {}}
          dontHide={true}
        ></PageLink>
        <PageLink
          text="TOS"
          navigateTo="tos"
          CloseMenu={() => {}}
          dontHide={true}
        ></PageLink>
        <PageLink
          text="Store"
          navigateTo="https://www.funker530.com/store/"
          CloseMenu={() => {}}
          dontHide={true}
        ></PageLink>
      </div>
    </div>
  );
}

export default FooterMenu;
