import { faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ReloadSymbol({ moveY, startY, swipeThreshold }) {
  const calculateRotation = () => {
    const swipeDistance = moveY - startY;
    if (swipeDistance >= swipeThreshold) {
      return 0;
    }
    const rotation = 360 * (swipeDistance / swipeThreshold - 1);
    return rotation;
  };

  return (
    <div
      className={"reloadSymbol" + (moveY !== 0 ? "" : " hide")}
      style={{
        top: `${Math.min((moveY - startY) / 2, 75)}px`,
        opacity: Math.min((moveY - startY) / swipeThreshold, 1),
        transform: `translateX(-50%) rotate(${calculateRotation()}deg)`,
      }}
    >
      <FontAwesomeIcon icon={faRotateRight} />
    </div>
  );
}
