import React, { useState, useEffect } from "react";
import AdminSidebar from "./AdminSidebar";
import AdminList from "./AdminList";
import { useParams } from "react-router-dom";
import { UseUserContext } from "../../Contexts/UserContext";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import AdminEditItem from "./AdminEditItem";

function Admin(props) {
  const { type, id } = useParams();
  const { user, loggingIn } = UseUserContext();
  const [refreshData, SetRefreshData] = useState(false);
  const { HandleLinkClick } = UseNavigationContext();

  if ((user == null && !loggingIn) || (user != null && !user?.isAdmin)) {
    HandleLinkClick(null, "");
  }

  function ChangeSidebarOption(e, option) {
    HandleLinkClick(e, "admin/" + option);
  }

  function RefreshData() {
    SetRefreshData(true);
  }

  useEffect(() => {
    if (refreshData) {
      SetRefreshData(false);
    }
  }, [refreshData]);

  return (
    !refreshData &&
    !loggingIn && (
      <div className="admin">
        <AdminSidebar
          ChangeSidebarOption={ChangeSidebarOption}
          currentOption={type.toLowerCase()}
          RefreshData={RefreshData}
        />
        {id != null ? (
          <AdminEditItem id={id} type={type.toLowerCase()} />
        ) : (
          <AdminList type={type.toLowerCase()} />
        )}
      </div>
    )
  );
}

export default Admin;
