import React, { memo, useState } from "react";
import * as SiteConstants from "../Constants/SiteConstants";
import * as Utilities from "../Constants/Utilities";
import { Icon } from "@iconify/react";
import { UseNavigationContext } from "../Contexts/NavigationContext";

function VideoListing(props) {
  const [touchStartPoint, SetTouchStartPoint] = useState([0, 0]);
  const { HandleLinkClick } = UseNavigationContext();

  const videoHref = "/video/" + props.video?.slug;

  let thumbnail = SiteConstants.DEFAULT_THUMBNAIL;

  const thumbnailUrls = props.video?.thumbnail?.renditions;
  if (thumbnailUrls != null) {
    thumbnail = SiteConstants.IMAGES_BASE_URL;
    thumbnail += window.matchMedia("(min-width: 1024px)").matches
      ? thumbnailUrls[0].file
      : thumbnailUrls[thumbnailUrls.length - 1].file;
  }

  return (
    <a
      href={videoHref}
      onClick={(e) => {
        if (e.type !== "click") {
          e.preventDefault();
          return;
        }

        HandleLinkClick(e, videoHref);
      }}
      onTouchStart={(e) => {
        SetTouchStartPoint([e.touches[0].clientX, e.touches[0].clientY]);
      }}
      onTouchEnd={(e) => {
        const endPoint = [
          e.changedTouches[0].clientX,
          e.changedTouches[0].clientY,
        ];

        if (
          Math.abs(endPoint[0] - touchStartPoint[0]) < 10 &&
          Math.abs(endPoint[1] - touchStartPoint[1]) < 10
        ) {
          HandleLinkClick(e, videoHref);
        }

        e.preventDefault();
        return;
      }}
      className={props.className}
    >
      {!props.placeholder && (
        <>
          <div className="videoThumbnail">
            <img
              src={thumbnail}
              alt={props.video?.title}
              loading="lazy"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = SiteConstants.DEFAULT_THUMBNAIL;
                e.target.style = "align-self: center;";
              }}
              style={
                props.video?.thumbnail?.renditions != null
                  ? {}
                  : { alignSelf: "center" }
              }
            />
          </div>
          <div className="listVideoTitle moreReadable">
            {props.video?.title}
          </div>
          <div className="videoData">
            {!props.loading ? (
              <>
                <div className="videoViews">
                  <Icon icon="ion:eye" />
                  {props.video?.viewCount}
                </div>
                <div className="videoDate">
                  {Utilities.GetPrettyDate(props.video?.publicationDate)}
                </div>
              </>
            ) : null}
          </div>
        </>
      )}
    </a>
  );
}

export default memo(VideoListing);
