import React from "react";
import { UseNavigationContext } from "../Contexts/NavigationContext";

import * as Constants from "../Constants/APIConstants";

function Avatar(props) {
  const { HandleLinkClick } = UseNavigationContext();

  const avatarUrl = props.useDefaultAuthorImage
    ? "/Funker_Premium_Logo.png"
    : props.src !== "" && props.src != null
    ? Constants.IMAGES_BASE_URL + props.src
    : "/default-avatar.png";

  const avatarHref =
    props.id === "" || props.id === "none" ? "/login" : "/user/" + props.id;

  return (
    <a
      href={avatarHref}
      onClick={(e) => {
        if (props.id == null) {
          e.preventDefault();
          return;
        }

        if (e.button === 1 || e.metaKey || e.ctrlKey) {
          return true;
        }

        HandleLinkClick(e, avatarHref);
      }}
    >
      <div className={"avatar" + (props.isPremium ? " premium" : "")}>
        <img
          className={
            "avatarImage" + (props.useDefaultAuthorImage ? " default" : "")
          }
          src={avatarUrl}
          alt={props.alt}
          loading="lazy"
        />
      </div>
    </a>
  );
}

export default Avatar;
