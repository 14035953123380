import React, { useState, useEffect } from "react";
import { UseVideoContext } from "../../Contexts/VideoContext";
import { UseUserContext } from "../../Contexts/UserContext";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import * as SiteConstants from "../../Constants/SiteConstants";
import * as APIConstants from "../../Constants/APIConstants";
import Form from "../../Generic/Form";
// import axios from "axios";

function AdminEditItem(props) {
  const [item, SetItem] = useState(null);
  const [fields, SetFields] = useState([]);
  const [categoryOptions, SetCategoryOptions] = useState([
    { value: "", label: "" },
  ]);
  const { HandleLinkClick } = UseNavigationContext();

  const {
    GetVideos,
    GetCategories,
    UploadToBlobStorage,
    SendToBunny,
    UpdateBunnyValues,
    AddUpdateWithFormData,
  } = UseVideoContext();
  const { GetUsers, user } = UseUserContext();

  const videoFields = [
    {
      label: "Title",
      value: "",
      type: "text",
      maxLength: 80,
      apiName: "title",
      required: true,
      processesToUrlField: "slug",
    },
    {
      label: "Description",
      value: "",
      type: "textarea",
      apiName: "description",
      required: true,
    },
    {
      label: "Author",
      value: "*self*",
      type: "text",
      apiName: "author",
      required: false,
      maxLength: 255,
      ReplaceDefaultFunction: (value) => {
        return value === "*self*" ? user.username : value;
      },
    },
    {
      label: "Publication Date",
      value: GetLocalISOTime(),
      type: "datetime-local",
      apiName: "publicationDate",
      required: true,
    },
    {
      label: "NSFW",
      value: false,
      type: "checkbox",
      apiName: "mature",
      required: false,
    },
    {
      label: "Slug",
      value: "",
      type: "text",
      apiName: "slug",
      maxLength: 100,
      required: true,
    },
    {
      label: "Featured",
      value: false,
      type: "checkbox",
      apiName: "featured",
    },
    {
      label: "Public",
      value: true,
      type: "checkbox",
      apiName: "public",
    },
    {
      label: "Upload Thumbnail",
      value: "",
      type: "file",
      accept: "image/*",
      apiName: "thumbnail",
      required: true,
      copyTo: "thumbnailPreview",
    },
    {
      label: "Thumbnail Preview",
      value: "",
      type: "image",
      apiName: "thumbnailPreview",
      required: false,
    },
    {
      label: "Upload Video",
      value: "",
      type: "file",
      accept: "video/*",
      apiName: "videoFile",
      dontSendToApi: true,
    },
    {
      label: "Bunny Video ID",
      value: "",
      type: "text",
      apiName: "bunnyId",
      maxLength: 50,
      required: false,
    },
    {
      label: "3rd Party Embed Code",
      value: "",
      type: "text",
      apiName: "connatixVid",
      required: false,
    },
    {
      label: "Categories",
      value: [],
      type: "multi-checkbox",
      apiName: "videoCategories",
      updateApiName: "categories",
      options: "categoryOptions",
      required: true,
    },
    {
      label: "Keywords",
      value: "",
      type: "text",
      maxLength: 100,
      apiName: "keywords",
      required: false,
    },
  ];

  const categoryFields = [
    {
      label: "Name",
      value: "",
      type: "text",
      apiName: "name",
      required: true,
      processesToUrlField: "slug",
    },
    {
      label: "Slug",
      value: "",
      type: "text",
      maxLength: 100,
      apiName: "slug",
      required: true,
    },
    {
      label: "Age Restricted",
      value: false,
      type: "checkbox",
      apiName: "ageRestricted",
      required: false,
    },
    {
      label: "Password",
      value: "",
      type: "text",
      apiName: "password",
      required: false,
    },
  ];

  const userFields = [
    {
      label: "Username",
      value: "",
      type: "text",
      apiName: "username",
      required: true,
    },
    {
      label: "Email",
      value: "",
      type: "text",
      apiName: "email",
      required: true,
    },
    {
      label: "First Name",
      value: "",
      type: "text",
      apiName: "firstName",
      disabled: true,
    },
    {
      label: "Last Name",
      value: "",
      type: "text",
      apiName: "lastName",
      disabled: true,
    },
    {
      label: "Email Status",
      value: "",
      type: "text",
      apiName: "emailStatus",
      disabled: true,
    },
    {
      label: "Date Joined",
      value: "",
      type: "datetime-local",
      apiName: "dateJoined",
      disabled: true,
    },
    {
      label: "Last Login",
      value: "",
      defaultValue: "Never Logged In",
      type: "datetime-local",
      apiName: "lastLogin",
      disabled: true,
    },
    {
      label: "Author Bio",
      value: "",
      type: "text",
      apiName: "bio",
      disabled: true,
    },
    {
      label: "Email Verified",
      value: "",
      type: "text",
      apiName: "emailConfirmed",
      disabled: true,
    },
    {
      label: "Groups",
      value: [],
      type: "multi-checkbox",
      multiSelect: true,
      apiName: "groups",
      options: "groupOptions",
    },
  ];

  const groupOptions = [
    { value: "1", label: "Moderator" },
    { value: "2", label: "Editor" },
    { value: "3", label: "Writer" },
  ];

  function GetLocalISOTime(date) {
    date = date == null ? new Date() : new Date(date);
    let offset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - offset);
    return date.toISOString().substring(0, 16);
  }

  function ProcessFields(defaultFields) {
    if (item == null && props.id != null && props.id !== "add") {
      return;
    }
    let newFields = [];
    for (let i = 0; i < defaultFields.length; i++) {
      let newField = { ...defaultFields[i] };
      if (item != null) {
        if (newField.type === "datetime-local") {
          newField.value = GetLocalISOTime(item[newField.apiName]);
        } else if (
          newField.apiName === "thumbnailPreview" &&
          item.thumbnail?.renditions?.length > 0
        ) {
          newField.value =
            SiteConstants.IMAGES_BASE_URL +
            item.thumbnail.renditions[item.thumbnail.renditions.length - 1]
              .file;
        } else if (
          newField.type === "multi-checkbox" &&
          newField.apiName !== "groups"
        ) {
          newField.value = item[newField.apiName]?.map(
            (category) => category.categoryId
          );
        } else if (newField.apiName === "groups") {
          newField.value = item[newField.apiName]?.map(
            (group) => "" + group.id
          );
        } else if (newField.apiName === "bio") {
          newField.value = item.userProfile.bio;
        } else if (newField.apiName === "emailConfirmed") {
          newField.value = item.userProfile.emailConfirmed;
        } else if (
          newField.type.includes("text") &&
          item[newField.apiName]?.trim != null
        ) {
          newField.value = item[newField.apiName].trim();
        } else {
          newField.value = item[newField.apiName];
        }
      }
      newFields.push(newField);
    }
    SetFields(newFields);
  }

  async function GetItem() {
    let result = null;
    if (props.id === "add") {
      SetItem(null);
      if (props.type === "videos") {
        GetCategoryOptions();
      }
      return;
    }
    switch (props.type) {
      case "videos":
        result = await GetVideos({
          id: props.id,
          allowPrivate: true,
          allowUnpublished: true,
          allowInvalid: true,
          includeAll: true,
        });
        GetCategoryOptions();
        break;
      case "categories":
        result = await GetCategories({ id: props.id, includeAll: true });
        break;
      case "users":
        result = await GetUsers({ username: props.id, includeProfile: true });
        break;
      default:
        break;
    }
    if (result.data instanceof Array) {
      SetItem(result.data[0]);
      return;
    }
    SetItem(null);
  }

  async function GetCategoryOptions() {
    let result = await GetCategories({});
    if (result.data instanceof Array) {
      let newOptions = [];
      for (let i = 0; i < result.data.length; i++) {
        newOptions.push({
          value: result.data[i].id,
          label: result.data[i].name,
        });
      }
      SetCategoryOptions(newOptions);
    }
  }

  function GenerateFields() {
    switch (props.type) {
      case "videos":
        ProcessFields(videoFields);
        break;
      case "categories":
        ProcessFields(categoryFields);
        break;
      case "users":
        ProcessFields(userFields);
        break;
      default:
        break;
    }
  }

  async function PreProcessVideo(formData) {
    let resultArray = [];
    if (
      formData?.videoFile?.value == null ||
      formData?.title?.value == null ||
      formData?.videoFile?.value === "" ||
      formData?.title?.value === "" ||
      (formData?.bunnyId?.value != null &&
        formData?.bunnyId?.value?.trim() !== "") ||
      (formData?.connatixVid?.value && formData.connatixVid.value.trim() !== "")
    ) {
      return null;
    }
    console.log("Uploading to Blob Storage");
    let result = await UploadToBlobStorage(
      formData.videoFile.value,
      formData.title.value
    );
    if (!(typeof result === "string" || result instanceof String)) {
      console.log("Error uploading to Blob Storage", result);
      return "Error uploading to Blob Storage";
    }
    console.log("Uploaded to Blob Storage", result);

    console.log("Sending to Bunny");
    let bunnyFormData = {
      url: result,
    };
    let bunnyUploadResult = await SendToBunny(bunnyFormData);
    if (
      !(
        typeof bunnyUploadResult === "string" ||
        bunnyUploadResult instanceof String
      )
    ) {
      console.log("Error sending to Bunny", bunnyUploadResult);
      return "Error sending to Bunny";
    }
    console.log("Sent to Bunny", bunnyUploadResult);
    resultArray.push({ value: bunnyUploadResult, apiName: "bunnyId" });

    return resultArray;
  }

  async function PostProcessVideo(formData, changedFields) {
    // let recachePostData = new FormData();
    // recachePostData.append(
    //   "url",
    //   window.location.host + "/video/" + formData.slug.value
    // );
    // let recacheResponse = await axios.post(
    //   APIConstants.API_URL + "RecacheUrl?code=" + APIConstants.API_CODE,
    //   recachePostData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );
    // console.log("Recache Result:", recacheResponse);
    if (
      formData?.bunnyId == null ||
      formData?.bunnyId === "" ||
      (!changedFields.includes("thumbnail") &&
        !changedFields.includes("title") &&
        !changedFields.includes("description")) ||
      (formData?.connatixVid?.value && formData.connatixVid.value.trim() !== "")
    ) {
      return null;
    }
    console.log("Delaying to let Bunny start processing the video");
    //delaying to let bunny start processing the video
    await new Promise((resolve) => setTimeout(resolve, 10000));
    let newAddedVideo = await GetVideos({
      slug: formData.slug.value,
      allowPrivate: true,
      allowUnpublished: true,
      allowInvalid: true,
      includeAll: true,
    });
    console.log("Updating Bunny values", newAddedVideo);
    let bunnyUpdateFormData = {
      bunnyId: newAddedVideo.data[0].bunnyId,
      title: formData.title.value,
      description: formData.description.value,
      thumbnailUrl:
        APIConstants.IMAGES_BASE_URL + newAddedVideo.data[0].thumbnail.file,
    };
    let bunnyUpdateResult = await UpdateBunnyValues(bunnyUpdateFormData);
    if (
      !(
        typeof bunnyUpdateResult === "string" ||
        bunnyUpdateResult instanceof String
      )
    ) {
      console.log("Error updating Bunny values", bunnyUpdateResult);
      return "Error updating Bunny values";
    }
    console.log("Updated Bunny values", bunnyUpdateResult);

    return true;
  }

  function GotoItem(e) {
    let firstPathPart =
      props.type === "categories"
        ? "category"
        : props.type.substring(0, props.type.length - 1);
    HandleLinkClick(e, firstPathPart + "/" + item.slug ?? item.username);
  }

  useEffect(() => {
    if (props.id != null) {
      GetItem();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => {
    GenerateFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return props.id === "add" || item != null ? (
    <Form
      item={item}
      type={props.type}
      fields={fields}
      singularType={props.type === "categories" ? "Category" : null}
      allowsDelete={true}
      PreProcessFunction={props.type === "videos" ? PreProcessVideo : null}
      PostProcessFunction={props.type === "videos" ? PostProcessVideo : null}
      categoryOptions={categoryOptions}
      groupOptions={groupOptions}
      AddUpdateFunction={AddUpdateWithFormData}
      RefreshData={GetItem}
      extraButtonText={"Go To "}
      extraButtonAddType={true}
      ExtraButtonFunction={GotoItem}
      cancelLink={"/admin/" + props.type}
      processingSteps={
        props.type === "videos"
          ? {
              preProcess: "Uploading Video...",
              postProcess: "Updating Bunny Values...",
            }
          : null
      }
    />
  ) : null;
}

export default AdminEditItem;
