import React, { useEffect, useState } from "react";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import Cookies from "universal-cookie";
import { UseUserContext } from "../../Contexts/UserContext";
import { GenerateTooltip, HandleEnter } from "../../Constants/Utilities";

function Login(props) {
  const cookies = new Cookies();
  const [username, SetUsername] = useState(cookies.get("username") ?? "");
  const [password, SetPassword] = useState("");
  const [remember, SetRemember] = useState(
    cookies.get("username") != null ? true : false
  );
  const [loginError, SetLoginError] = useState(false);
  const { user, loading, returnTo, Login } = UseUserContext();

  const { HandleLinkClick } = UseNavigationContext();

  function HandleInput(e) {
    const { name, value, checked } = e.target;
    switch (name) {
      case "username":
        SetUsername(value);
        break;
      case "password":
        SetPassword(value);
        break;
      case "remember":
        SetRemember(checked);
        break;
      default:
        break;
    }
  }

  async function HandleLogin() {
    if (username === "" || password === "") {
      return;
    }

    if (remember) {
      cookies.set("username", username, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
      });
    } else {
      cookies.remove("username", { path: "/" });
    }

    let result = await Login(username, password);
    if (result?.sessionKey == null) {
      SetLoginError(result);
      return;
    }

    HandleLinkClick(null, returnTo);
  }

  useEffect(() => {
    if (user && user !== "none") {
      HandleLinkClick(
        null,
        returnTo ? returnTo : "user/" + user.username,
        true
      );
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    !loading && (
      <>
        <div className="loginContainer">
          <div className="loginBox">
            <div className="loginLogoContainer">
              <img
                src="https://images.funker530.com/images/media/images/FunkerLogo.original.png"
                alt="Funker530 Logo"
                className="loginPageLogo"
              />

              <div
                className={
                  "authenticationError" + (!loginError ? " hidden" : "")
                }
              >
                Your username/email or password is incorrect. Please try again.
              </div>
            </div>
            <div className="formContainer">
              <div
                className="loginInputContainer"
                onKeyUp={(e) => HandleEnter(e, HandleLogin)}
              >
                <input
                  type="text"
                  placeholder="Email or Username"
                  className="loginInput"
                  name="username"
                  onChange={HandleInput}
                  value={username}
                />
                <input
                  type="password"
                  placeholder="Password"
                  className="loginInput"
                  name="password"
                  onChange={HandleInput}
                />
                <div className="checkboxInput">
                  <input
                    type="checkbox"
                    name="remember"
                    onChange={HandleInput}
                    checked={remember}
                  />
                  <label>Remember Me</label>
                </div>
                <a href="/resetpassword" className="forgotPassword">
                  Forgot Password?
                </a>
              </div>
              <div
                className={
                  "loginButton funkerButton " +
                  (username.length === 0 || password.length === 0
                    ? "disabledButton"
                    : "")
                }
                onClick={HandleLogin}
              >
                LOG IN
              </div>
              <div
                className="loginButton funkerButton alternateButton"
                onClick={() => HandleLinkClick(null, "/register")}
              >
                REGISTER
              </div>
            </div>
          </div>
        </div>
        {(username.length === 0 || password.length === 0) &&
          GenerateTooltip(
            ".disabledButton",
            "custom",
            "Please enter a username/email and password to log in."
          )}
      </>
    )
  );
}

export default Login;
