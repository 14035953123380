import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import FunkerTable from "./FunkerTable";
import PageSelector from "./PageSelector";

function FunkerTableItem(props) {
  const [expanded, SetExpanded] = useState(false);
  const [pages, SetPages] = useState([1]);

  function ExpandItem() {
    SetExpanded(!expanded);
  }

  function GetPages() {
    let pages = [];
    if (
      props.totalPages == null ||
      props.currentPage == null ||
      props.totalPages <= 1
    ) {
      pages.push(1);
      return pages;
    }
    let startPage = props.currentPage - 2;
    if (startPage < 1) {
      startPage = 1;
    }
    if (startPage + 5 > props.totalPages) {
      startPage = props.totalPages - 4;
    }
    for (let i = startPage; i < startPage + 5 && i <= props.totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }

  useEffect(() => {
    SetPages(GetPages());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentPage, props.totalPages]);

  return (
    <div
      className={
        "funkerTableItem" +
        (props.isHeader ? " header" : props.isTotalRow ? " totalRow" : "") +
        (props.highlighted ? " highlighted" : "") +
        (props.isPagingRow
          ? " pagingRow" + (props.totalPages <= 1 ? " allDisabled" : "")
          : "")
      }
      style={
        !props.isPagingRow
          ? {
              gridTemplateColumns:
                (props.subTableColumns ? "5rem " : "") + props.columnWidths,
            }
          : null
      }
      onClick={props.onClickRow ? (e) => props.onClickRow(e, props.item) : null}
    >
      {props.isPagingRow ? (
        <PageSelector
          currentPage={props.currentPage}
          totalPages={props.totalPages}
          HandlePageChange={props.HandlePageChange}
          pages={pages}
        />
      ) : (
        // /* <div
        //   className={
        //     "pagingButton" + (props.currentPage === 1 ? " disabled" : "")
        //   }
        //   onClick={() => props.HandlePageChange("first")}
        // >
        //   <Icon icon="ph:skip-back" />
        // </div>
        // <div
        //   className={
        //     "pagingButton" + (props.currentPage === 1 ? " disabled" : "")
        //   }
        //   onClick={() => props.HandlePageChange("previous")}
        // >
        //   <Icon icon="material-symbols:arrow-back" />
        // </div>
        // {pages.map((page, index) => (
        //   <div
        //     key={index}
        //     className={
        //       "pagingButton" +
        //       (page === props.currentPage && props.totalPages > 1
        //         ? " active"
        //         : "")
        //     }
        //     onClick={() => props.HandlePageChange(page)}
        //   >
        //     {page}
        //   </div>
        // ))}
        // <div
        //   className={
        //     "pagingButton" +
        //     (props.currentPage === props.totalPages ? " disabled" : "")
        //   }
        //   onClick={() => props.HandlePageChange("next")}
        // >
        //   <Icon icon="material-symbols:arrow-forward" />
        // </div>
        // <div
        //   className={
        //     "pagingButton" +
        //     (props.currentPage === props.totalPages ? " disabled" : "")
        //   }
        //   onClick={() => props.HandlePageChange("last")}
        // >
        //   <Icon icon="ph:skip-forward" />
        // </div> */
        <>
          {props.subTableColumns != null ? (
            props.subTableItems != null && props.subTableItems.length > 0 ? (
              <Icon
                className="funkerTableItemCell expandIcon"
                icon={
                  expanded ? "ic:round-expand-more" : "ic:round-expand-less"
                }
                onClick={ExpandItem}
              />
            ) : (
              <div className="funkerTableItemCell"></div>
            )
          ) : null}
          {props.columns.map((column, index) => (
            <div
              key={index}
              style={{
                textAlign: column.GetAlignment(props.isHeader),
                justifyContent: column.GetAlignment(props.isHeader),
                fontSize: (props.isHeader ? 1 : column.fontSize) + "rem",
              }}
              onClick={() => {
                if (props.isHeader) {
                  if (column.HeaderOnClick != null) {
                    column.HeaderOnClick();
                  }
                  if (column.sortable) {
                    props.ChangeSort(column);
                  }
                  return;
                }
                if (column.CellOnClick != null) {
                  column.CellOnClick(props.item);
                }
                if (column.ValueOnClick != null) {
                  column.ValueOnClick(column.GetValue(props.item));
                }
              }}
              className={
                "funkerTableItemCell" +
                (props.isHeader && column.sortable ? " withPointer" : "") +
                (column.hideOnMobile ? " hideOnMobile" : "") +
                (column.cellClass && !props.isHeader
                  ? " " + column.cellClass
                  : "")
              }
              data-tip={column.GetToolTip(props.item)}
            >
              {props.isHeader ? (
                <>
                  <span>{column.header}</span>
                  {column.header === props.currentSortColumn?.header ? (
                    <Icon
                      className="sortByArrow"
                      icon={
                        (props.currentSortDescending &&
                          column.sortType !== "string") ||
                        (!props.currentSortDescending &&
                          column.sortType === "string")
                          ? "akar-icons:arrow-down"
                          : "akar-icons:arrow-up"
                      }
                    />
                  ) : null}
                </>
              ) : (
                column.GetValue(
                  props.item,
                  false,
                  column.truncateLongTextLength
                )
              )}
            </div>
          ))}
        </>
      )}
      {props.subTableColumns != null &&
      props.subTableItems != null &&
      props.subTableItems.length > 0 &&
      expanded ? (
        <FunkerTable
          columns={props.subTableColumns}
          items={props.subTableItems}
          isSubTable={true}
        />
      ) : null}
    </div>
  );
}

export default FunkerTableItem;
