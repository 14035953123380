import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { UseNavigationContext } from "../../Contexts/NavigationContext";

function BaseRedirector(props) {
  const { category } = useParams();
  const { HandleLinkClick } = UseNavigationContext();

  function ProcessRedirection() {
    HandleLinkClick(
      null,
      props.redirectTo.replace("{category}", category) + window.location.search
    );
  }

  useEffect(() => {
    ProcessRedirection();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="redirectingNotice">Redirecting...</div>;
}

export default BaseRedirector;
