import React, { useEffect, useRef, useState } from "react";
import Avatar from "../Generic/Avatar";
import { Icon } from "@iconify/react";
import VideoLists from "../Lists/VideoLists";
import Markdown from "markdown-to-jsx";
import * as Utilities from "../Constants/Utilities";
import ErrorBoundary from "../Generic/ErrorBoundary";
import Ad from "../Generic/Ad";
import { UseNavigationContext } from "../Contexts/NavigationContext";
import Comments from "../Comments/Comments";
import { UseUserContext } from "../Contexts/UserContext";
import { UseVideoContext } from "../Contexts/VideoContext";
import CommentContext from "../Contexts/CommentContext";
import ShareModal from "../Generic/ShareModal";
import * as Constants from "../Constants/APIConstants";

function VideoInfo(props) {
  const descriptionContainerRef = useRef(null);
  const descriptionRef = useRef(null);
  const [showReadMore, SetShowReadMore] = useState(false);
  //eslint-disable-next-line
  const [descriptionTruncated, SetDescriptionTruncated] = useState(true);
  const [showShareModal, SetShowShareModal] = useState(false);
  const [liked, SetLiked] = useState(false);
  const [likedId, SetLikedId] = useState(null);

  const { user } = UseUserContext();
  const { LikeVideo } = UseVideoContext();

  const { HandleLinkClick } = UseNavigationContext();

  const lists = [
    {
      title: "Recommended",
      listId: "videoRecommended",
      adAtEnd: true,
    },
  ];

  function DescriptionTooLong() {
    SetShowReadMore(
      descriptionRef.current?.clientHeight >
        descriptionContainerRef.current?.clientHeight
    );
  }

  function ProcessDescription() {
    if (!props.video?.description) {
      return "";
    }
    let processedDescription = props.video?.description;
    processedDescription = processedDescription.replace(
      /<script[\s\S]*?>[\s\S]*?<\/script>/gi,
      ""
    );
    if (
      processedDescription.includes("twitter-tweet") &&
      !processedDescription.includes("data-theme")
    ) {
      processedDescription = processedDescription.replace(
        /<blockquote/g,
        '<blockquote data-theme="dark"'
      );
    }
    if (processedDescription.includes("\r\n")) {
      processedDescription = processedDescription.replace(/\n/g, "<br>");
    }
    if (processedDescription.endsWith("</div>")) {
      return processedDescription.substring(
        0,
        processedDescription.lastIndexOf("</div>")
      );
    }
    return processedDescription;
  }

  function ChangeShareModal() {
    if (showShareModal === true) {
      SetShowShareModal(false);
      return;
    }
    SetShowShareModal(true);
  }

  function GoToVideo(e) {
    if (window.location.pathname.includes("/video/")) {
      document
        .getElementById("CommentSectionContainer")
        .scrollIntoView({ behavior: "smooth" });
      return;
    }
    HandleLinkClick(e, "video/" + props.video?.slug);
  }

  function ShowFullDescription() {
    SetDescriptionTruncated(false);
    SetShowReadMore(false);
  }

  function GetLikeAdjustment() {
    let modifier = 0;
    if (liked && !props.video?.votes[0]?.upvote) {
      modifier = 1;
    } else if (!liked && props.video?.votes[0]?.upvote) {
      modifier = -1;
    }
    return modifier;
  }

  async function LikeVideoFunction(e) {
    if (!user?.id) {
      HandleLinkClick(e, "login");
      return;
    }
    let newValue = !liked;
    SetLiked(newValue);
    let newLikeId = await LikeVideo(
      props.video?.id,
      newValue,
      likedId ?? props.video?.votes[0]?.id
    );
    if (+newLikeId > 0) {
      SetLikedId(newLikeId);
    }
  }

  useEffect(() => {
    if (props.video?.description?.includes("twitter-tweet")) {
      require("https://platform.twitter.com/widgets.js");
    }
    setTimeout(() => {
      DescriptionTooLong();
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.video?.description]);

  useEffect(() => {
    if (
      props.video?.votes?.length > 0 &&
      props.video.votes[0].userId === user?.id
    ) {
      SetLiked(props.video.votes[0].upvote);
      SetLikedId(props.video.votes[0].id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.video?.id]);

  useEffect(() => {
    if (!descriptionTruncated) {
      descriptionContainerRef.current?.classList.add("fullDescription");
    }
  }, [descriptionTruncated]);

  return (
    <div className={"videoInfo" + (user?.isPremium ? " premium" : "")}>
      <ShareModal
        pathArray={["video", props.video?.slug]}
        title={props.video?.title}
        showShareModal={showShareModal}
        thumbnail={
          props.video?.thumbnail?.file != null
            ? Constants.IMAGES_BASE_URL + props.video?.thumbnail?.file
            : null
        }
        ChangeShareModal={ChangeShareModal}
      ></ShareModal>
      <div className="videoTitle" onClick={props.videoHeading && GoToVideo}>
        <div className="videoHeading">{props.videoHeading}</div>
        {props.video?.title}
      </div>
      <div className="authorInfo">
        <Avatar
          src={props.video?.authorInfo?.avatar}
          useDefaultAuthorImage={
            props.video?.authorInfo?.avatar == null ||
            props.video?.authorInfo?.avatar === ""
          }
          alt="Author Image"
          id={props.video?.authorInfo?.username}
        />
        <div className="authorName">
          {props.video?.authorInfo?.name ??
            props.video?.authorInfo?.username ??
            "Funker530 Team"}
        </div>
      </div>
      <div
        className={
          "videoDescription moreReadable" +
          (descriptionTruncated ? " truncated" : "")
        }
        ref={descriptionContainerRef}
      >
        <div className="description" ref={descriptionRef}>
          <ErrorBoundary customError="Error loading description.">
            <div dangerouslySetInnerHTML={{ __html: ProcessDescription() }} />
            {props.video?.authorInfo?.authorInfo &&
              !props.video?.description
                .toLowerCase()
                .includes("about the author") && (
                <div className="aboutAuthor">
                  <div className="aboutAuthorHeader">About The Author</div>
                  <Avatar
                    src={props.video?.authorInfo?.avatar}
                    alt="Author Image"
                    id={props.video?.authorInfo?.username}
                  />
                  <div className="aboutAuthorName">
                    {props.video?.authorInfo?.name}
                  </div>
                  <div className="aboutAuthorBody">
                    <Markdown>{props.video?.authorInfo?.authorInfo}</Markdown>
                  </div>
                </div>
              )}
          </ErrorBoundary>
        </div>
        {showReadMore ? (
          <div className="readMore">
            <div className="readMoreFader"></div>
            <div
              className="readMoreButton"
              onClick={props.homePage ? GoToVideo : ShowFullDescription}
            >
              <div>Read More</div>
              <div>▼</div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="videoIcons">
        <div className="iconContainer">
          <span className="iconCircle">
            <Icon icon="oui:calendar" />
          </span>
          <div className="relativeAnchor">
            <span className="iconStatCircle">
              {Utilities.GetPrettyDate(props.video?.publicationDate, true)}
            </span>
          </div>
        </div>
        <div className="iconContainer">
          <span className="iconCircle">
            <Icon icon="ion:eye" />
          </span>
          <div className="relativeAnchor">
            <span className="iconStatCircle">
              {Utilities.GetPrettyNumber(props.video?.viewCount)}
            </span>
          </div>
        </div>
        <div className="iconContainer clickable" onClick={LikeVideoFunction}>
          <span className={"iconCircle" + (liked ? " liked" : "")}>
            <Icon icon="ion:thumbs-up-outline" />
          </span>
          <div className="relativeAnchor">
            <span className="iconStatCircle">
              {Utilities.GetPrettyNumber(
                (props.video?.likes ?? 0) + GetLikeAdjustment()
              )}
            </span>
          </div>
        </div>
        <div className="iconContainer clickable" onClick={GoToVideo}>
          <span className="iconCircle">
            <Icon icon="vaadin:comment" />
          </span>
          <div className="relativeAnchor">
            <span id="commentCountStatCircle" className="iconStatCircle">
              {props.video?.numberOfComments ?? 0}
            </span>
          </div>
        </div>
        <div className="iconContainer clickable" onClick={ChangeShareModal}>
          <span className="iconCircle">
            <Icon icon="material-symbols:share" />
          </span>
        </div>
      </div>
      <Ad amount={1} type="banner" />
      {props.biggerVideo ? (
        <>
          <VideoLists
            lists={lists}
            replaceRecommended={true}
            tabbed={false}
            videos={props.randomVideos != null ? props.randomVideos : null}
            biggerThumbnails={props.biggerThumbnails}
            numberOfAdsInMiddle={1}
          />
          <CommentContext
            articleId={props.video?.id}
            commentsFromVideo={props.video?.comments}
            amountPerPage={20}
          >
            <Comments />
          </CommentContext>
        </>
      ) : null}
    </div>
  );
}

export default VideoInfo;
