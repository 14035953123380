import React from "react";
import NavBar from "./NavBar";

// import Cookies from "universal-cookie";
// import { Icon } from "@iconify/react/dist/iconify.js";
// import { UseUserContext } from "../Contexts/UserContext";
// import Median from "median-js-bridge";

function Header() {
  // const { returnTo } = UseUserContext();
  // const cookies = new Cookies();
  // const [hideBetaBanner, SetHideBetaBanner] = useState(
  //   Median.isNativeApp() ||
  //     cookies.get("hidebetabanner") === true ||
  //     cookies.get("hidebetabanner")?.toLowerCase() === "true"
  // );
  // const [correspondingPath, SetCorrespondingPath] = useState(
  //   "https://www.funker530.com" + returnTo
  // );

  // function LeaveBeta(e) {
  //   e.preventDefault();
  //   if (Median.isNativeApp()) {
  //     return;
  //   }
  //   cookies.set("beta", "false", {
  //     path: "/",
  //     domain: "." + window.location.hostname.split(".").slice(-2).join("."),
  //     maxAge: 60 * 60 * 24 * 30,
  //   });
  //   setTimeout(() => {
  //     window.location.href = correspondingPath;
  //   }, 150);
  // }

  // function ToggleHideBanner() {
  //   SetHideBetaBanner(Median.isNativeApp() || !hideBetaBanner);

  //   cookies.set("hideBetaBanner", Median.isNativeApp() || !hideBetaBanner, {
  //     path: "/",
  //     domain: "." + window.location.hostname.split(".").slice(-2).join("."),
  //     maxAge: 60 * 60 * 24 * 30,
  //   });
  // }

  // useEffect(() => {
  //   SetCorrespondingPath("https://www.funker530.com" + returnTo);

  //   // eslint-disable-next-line
  // // }, [returnTo, hideBetaBanner]);

  // useEffect(() => {
  //   if (Median.isNativeApp()) {
  //     SetHideBetaBanner(true);
  //     cookies.set("hideBetaBanner", true, {
  //       path: "/",
  //       domain: "." + window.location.hostname.split(".").slice(-2).join("."),
  //       maxAge: 60 * 60 * 24 * 30,
  //     });
  //     cookies.set("beta", "true", {
  //       path: "/",
  //       domain: "." + window.location.hostname.split(".").slice(-2).join("."),
  //       maxAge: 60 * 60 * 24 * 30,
  //     });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="header">
      {/* <div className="bannerAnchor">
        <Icon
          icon="material-symbols:info-outline"
          className={"showArrow" + (hideBetaBanner ? "" : " hidden")}
          onClick={ToggleHideBanner}
        />
      </div>
      <div
        className={"premiumBannerContainer" + (hideBetaBanner ? " hidden" : "")}
      >
        <div className={"premiumBanner"}>
          {"You are currently on the Beta version of Funker530.com. "}
          <a href={correspondingPath} onClick={LeaveBeta}>
            Click here
          </a>
          {" to go back to the main site."}
        </div>
        <Icon
          icon="ic:outline-close"
          className="closeButton"
          onClick={ToggleHideBanner}
        />
      </div> */}
      <NavBar />
    </div>
  );
}

export default Header;
