import React, { useState } from "react";
import NavMenu from "./NavMenu";
import { UseNavigationContext } from "../Contexts/NavigationContext";

function PageLink(props) {
  const [NavMenuOpen, SetNavMenuOpen] = useState(false);
  const { HandleLinkClick } = UseNavigationContext();

  function HandleClick() {
    if (props.openMenuOnClick) {
      if (props.navMenuOptions != null && props.navMenuOptions.length > 0) {
        SetNavMenuOpen(!NavMenuOpen);
        props.CloseMenu();
      }
      return;
    }
    props.CloseMenu();
  }

  function PathContains(path) {
    if (path == null) {
      return false;
    }
    let contains = window.location.pathname.includes(path);
    if (props.isHome) {
      let ignorePaths = path.split(",");
      contains = true;
      for (let i = 0; i < ignorePaths.length; i++) {
        if (window.location.pathname.includes(ignorePaths[i])) {
          contains = false;
          break;
        }
      }
    }
    return contains;
  }

  const isCurrentPath = PathContains(props.path);

  function HandleMouseOver() {
    if (
      !props.openMenuOnClick &&
      props.navMenuOptions != null &&
      props.navMenuOptions.length > 0
    ) {
      SetNavMenuOpen(true);
    }
  }

  function HandleMouseOut() {
    if (
      !props.openMenuOnClick &&
      props.navMenuOptions != null &&
      props.navMenuOptions.length > 0
    ) {
      SetNavMenuOpen(false);
    }
  }

  return (
    <div
      className={
        "pageLink" +
        (isCurrentPath ? " active" : "") +
        (props.premium ? " premium" : "") +
        (props.dontHide ? " dontHide" : "")
      }
      onMouseOver={HandleMouseOver}
      onMouseOut={HandleMouseOut}
    >
      <a
        href={props.navigateTo}
        className="gridA"
        onClick={(e) => {
          HandleLinkClick(e, props.navigateTo);
          HandleClick();
        }}
      >
        <div className="linkText">{props.text}</div>
        <div className="underline"></div>
      </a>
      <NavMenu
        open={NavMenuOpen}
        options={props.navMenuOptions}
        CloseMenu={props.CloseMenu}
      />
    </div>
  );
}

export default PageLink;
