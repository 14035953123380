import React, { useEffect, useState } from "react";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import { useParams, Link } from "react-router-dom";
import { UseUserContext } from "../../Contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/sharp-solid-svg-icons";

function DeleteProfile(props) {
  const { user, loading, DeleteUserAndProfile } = UseUserContext();
  const { username } = useParams();
  const [usernameToDelete, SetUsernameToDelete] = useState("");
  const [deleteEnabled, SetDeleteEnabled] = useState(false);

  const { HandleLinkClick } = UseNavigationContext();

  function HandleInput(e) {
    SetUsernameToDelete(e.target.value);
    SetDeleteEnabled(e.target.value === user?.username);
  }

  async function HandleDelete() {
    if (!deleteEnabled) {
      return;
    }
    let response = await DeleteUserAndProfile();

    if (response.error) {
      alert(response.error);
      return;
    }

    HandleLinkClick(null, "");
  }

  useEffect(() => {
    if (user === "none" || user == null || user.username !== username) {
      HandleLinkClick(null, "login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !loading && (
      <div className="deleteProfile">
        <div className="deleteProfileContainer">
          <div className="deleteProfileWarning">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="iconWarning"
            />
            Delete profile? This cannot be undone
          </div>
          <div className="deleteProfileConfirmation moreReadable">
            {`To delete your profile, type your username (${user.username}) below and hit the delete button. Once confirmed, this procedure cannot be undone.`}
            <div className="deleteProfileInput">
              <input
                type="text"
                placeholder="Type your username here"
                className="deleteProfileInputField"
                onChange={HandleInput}
                value={usernameToDelete}
              />
            </div>
            <div className="deleteProfileButtons">
              <Link to={`/user/${user?.username}`} className="buttonLink">
                <div className="deleteButton funkerButton alternateButton">
                  Cancel
                </div>
              </Link>
              <div
                className={
                  "deleteButton funkerButton" +
                  (deleteEnabled ? "" : " disabled")
                }
                onClick={HandleDelete}
              >
                Delete Profile
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default DeleteProfile;
