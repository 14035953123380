import React, { useEffect } from "react";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import { UseUserContext } from "../../Contexts/UserContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { faRectanglePro } from "@fortawesome/pro-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/sharp-solid-svg-icons";
import * as SiteConstants from "../../Constants/SiteConstants";
import Form from "../../Generic/Form";

function EditProfile(props) {
  const { user, loading, UpdateUserProfile, RefreshUser } = UseUserContext();

  const { HandleLinkClick } = UseNavigationContext();

  const userProfileFields = [
    {
      label:
        "I confirm I am over the age of 18 and wish to view posts flagged as mature.",
      type: "checkbox",
      apiName: "ageOver18",
      required: false,
      value: user?.ageOver18,
    },
    {
      label: "Email Address",
      value: user?.email,
      type: "email",
      apiName: "email",
      required: false,
    },
    {
      label: "Bio",
      value: user?.userProfile?.bio ?? "",
      type: "textarea",
      apiName: "bio",
      required: false,
      subSection: "userProfile",
    },
    {
      label: "Author First Name",
      value: user?.firstName ?? "",
      type: "text",
      apiName: "firstName",
      required: false,
      active: user?.isAdmin,
    },
    {
      label: "Author Last Name",
      value: user?.lastName ?? "",
      type: "text",
      apiName: "lastName",
      required: false,
      active: user?.isAdmin,
    },
    {
      label: "Author Info",
      value: user?.userProfile?.authorInfo ?? "",
      type: "textarea",
      apiName: "authorInfo",
      required: false,
      subSection: "userProfile",
      active: user?.isAdmin,
    },
    {
      label: "Disable autoplay on videos",
      icon: (
        <FontAwesomeIcon
          icon={faRectanglePro}
          className="editProfilePremiumIcon"
        />
      ),
      type: "checkbox",
      apiName: "autoplayDisabled",
      required: false,
      value: user?.userProfile?.autoplayDisabled ?? false,
      disabled: !user?.isPremium ?? false,
    },
    {
      label: "Make Liked Videos Public",
      type: "checkbox",
      apiName: "likedPublic",
      required: false,
      subSection: "userProfile",
      value: user?.userProfile?.likedPublic ?? false,
    },
    {
      label: "Change Avatar (Recommended Size: 250x250px)",
      type: "file",
      apiName: "avatar",
      required: false,
      accept: "image/*",
      copyTo: "avatarPreview",
      subSection: "userProfile",
    },
    {
      label: "Avatar Preview",
      type: "image",
      apiName: "avatarPreview",
      required: false,
      subSection: "userProfile",
      value: user?.userProfile?.avatar
        ? SiteConstants.IMAGES_BASE_URL + user.userProfile.avatar
        : "",
    },
  ];

  function ReturnToProfile() {
    RefreshUser();
    HandleLinkClick(null, `user/${user.username}`);
  }

  useEffect(() => {
    if (user === null || user === "none") {
      HandleLinkClick(null, "login");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !loading && (
      <div className="editProfile">
        <div className="editProfileContainer">
          <div className="editProfileHeader">
            <FontAwesomeIcon icon={faEdit} className="icon" />
            Edit Profile
            <Link to={`/user/${user?.username}/delete`} className="buttonLink">
              <div className="deleteButton funkerButton">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="icon"
                />
                Delete Profile
              </div>
            </Link>
          </div>
          <div className="editProfileForm">
            <Form
              item={user}
              fields={userProfileFields}
              singularType="user"
              AddUpdateFunction={UpdateUserProfile}
              RefreshData={ReturnToProfile}
              cancelLink={`/user/${user.username}`}
            />
          </div>
        </div>
      </div>
    )
  );
}

export default EditProfile;
