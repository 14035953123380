import React, { useState, useEffect, useRef } from "react";
import VideoReel from "../../Video/VideoReel";
import { useParams } from "react-router-dom";
import { UseVideoContext } from "../../Contexts/VideoContext";
import { UseUserContext } from "../../Contexts/UserContext";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import * as SiteConstants from "../../Constants/SiteConstants";

function VideoPage(props) {
  const { slug } = useParams();
  const { GetVideos } = UseVideoContext();
  const { SetReturnToPath, loggingIn } = UseUserContext();
  const [video, SetVideo] = useState(null);
  const [randomVideos, SetRandomVideos] = useState([]);
  const { AssignSeoData } = UseNavigationContext();

  const mainRef = useRef();

  let thumbnail_file = "";
  if (video?.thumbnail?.file != null) {
    thumbnail_file = SiteConstants.IMAGES_BASE_URL + video?.thumbnail?.file;
  }

  async function LoadVideo() {
    let newVideo = await GetVideos({
      slug: slug,
      allowPrivate: true,
      allowUnpublished: true,
      allowInvalid: true,
      hideNSFW: false,
    });
    if (newVideo?.data instanceof Array) {
      SetVideo(newVideo.data[0]);
    }
  }

  async function GetRandomVideos() {
    let randomList = await GetVideos({
      amount: 6,
      random: true,
    });

    if (randomList?.data instanceof Array) {
      SetRandomVideos(randomList.data);
    }
  }

  useEffect(() => {
    if (loggingIn) {
      return;
    }
    LoadVideo();
    GetRandomVideos();
    SetReturnToPath("/video/" + slug);
    if (mainRef.current != null) {
      mainRef.current.scrollIntoView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggingIn, mainRef, slug]);

  useEffect(() => {
    if (mainRef.current != null) {
      mainRef.current.scrollIntoView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    if (video != null) {
      AssignSeoData({
        title: video.title,
        description: video.description,
        image: thumbnail_file ?? "",
        keywords: video.keywords,
        author: video.author,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  return (
    randomVideos.length > 0 && (
      <div className="video mainContent" ref={mainRef}>
        <VideoReel
          mainVideo={video}
          previousVideo={randomVideos?.length > 1 ? randomVideos[1] : null}
          nextVideo={randomVideos?.length > 0 ? randomVideos[0] : null}
          biggerVideo={true}
          randomVideos={randomVideos}
          biggerThumbnails={true}
        />
      </div>
    )
  );
}

export default VideoPage;
