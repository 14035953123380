import React, { useState, useEffect } from "react";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import * as Utilities from "../../Constants/Utilities";
import { UseUserContext } from "../../Contexts/UserContext";

function Register(props) {
  const [username, SetUsername] = useState("");
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [isEmailValid, SetIsEmailValid] = useState(true);
  const [isPasswordValid, SetIsPasswordValid] = useState(null);
  const [passwordValidationFields, SetPasswordValidationFields] = useState({
    lengthValid: false,
    hasNumber: false,
    hasUppercase: false,
    hasLowercase: false,
    hasSpecialCharacter: false,
  });
  const [buttonDisabled, SetButtonDisabled] = useState(true);
  const [ageConfirmation, SetAgeConfirmation] = useState(false);
  const [registrationError, SetRegistrationError] = useState(false);

  const { user, loading, Register, returnTo } = UseUserContext();

  const { HandleLinkClick } = UseNavigationContext();

  function HandleInput(e) {
    const { name, value, checked } = e.target;
    switch (name) {
      case "username":
        SetUsername(value);
        break;
      case "email":
        SetEmail(value);
        break;
      case "password":
        SetPassword(value);
        break;
      case "ageConfirmation":
        SetAgeConfirmation(checked);
        break;
      default:
        break;
    }
  }

  async function HandleRegistration() {
    if (buttonDisabled) {
      if (!isEmailValid || email.length === 0) {
        SetIsEmailValid(false);
        document.querySelector("#email").focus();
      } else if (!isPasswordValid || password.length === 0) {
        SetIsPasswordValid(false);
        document.querySelector("#password").focus();
      }

      return;
    }

    let result = await Register(username, email, password, ageConfirmation);

    if (result?.status === 400) {
      SetRegistrationError(result.data);
      return;
    }

    HandleLinkClick(null, returnTo);
  }

  useEffect(() => {
    if (email.length > 0) {
      SetIsEmailValid(Utilities.ValidateField("email", email));
    }
    if (password.length > 0) {
      const passwordFields = Utilities.ValidateField("password", password);
      SetPasswordValidationFields(passwordFields);

      const isValid = Object.values(passwordFields).every(
        (field) => field === true
      );
      SetIsPasswordValid(isValid);
    } else {
      SetIsPasswordValid(null);
      SetPasswordValidationFields({
        lengthValid: false,
        hasNumber: false,
        hasUppercase: false,
        hasLowercase: false,
        hasSpecialCharacter: false,
      });
    }

    if (
      username.length > 0 &&
      email.length > 0 &&
      password.length > 0 &&
      isEmailValid &&
      isPasswordValid
    ) {
      SetButtonDisabled(false);
    } else {
      SetButtonDisabled(true);
    }
  }, [username, email, password, isEmailValid, isPasswordValid]);

  if (user && user !== "none") {
    HandleLinkClick(null, returnTo ? returnTo : "user/" + user.username);
    return;
  }
  return (
    !loading && (
      <>
        <div className="loginContainer">
          <div className="loginBox">
            <div className="loginLogoContainer">
              <img
                src="https://images.funker530.com/images/media/images/FunkerLogo.original.png"
                alt="Funker530 Logo"
                className="loginPageLogo"
              />
              <div
                className={
                  "authenticationError" + (!registrationError ? " hidden" : "")
                }
              >
                {registrationError}
              </div>
            </div>
            <div className="formContainer">
              <div
                className="loginInputContainer"
                onKeyUp={(e) => Utilities.HandleEnter(e, HandleRegistration)}
              >
                <input
                  type="text"
                  placeholder="Username"
                  className="loginInput"
                  name="username"
                  onChange={HandleInput}
                  value={username}
                  id="username"
                />
                <input
                  type="email"
                  placeholder="Email (Verification Link Sent After Signup)"
                  className={isEmailValid ? "loginInput" : "loginInput invalid"}
                  name="email"
                  onChange={HandleInput}
                  value={email}
                  id="email"
                />
                <input
                  type="password"
                  placeholder="Password"
                  className={
                    isPasswordValid == null || isPasswordValid
                      ? "loginInput"
                      : "loginInput invalid"
                  }
                  name="password"
                  onChange={HandleInput}
                  id="password"
                />
                <div className="checkboxInput">
                  <input
                    type="checkbox"
                    name="ageConfirmation"
                    onChange={HandleInput}
                    checked={ageConfirmation}
                  />
                  <label>
                    I confirm that I am over the age of 18 and wish to view
                    posts flagged as mature
                  </label>
                </div>
              </div>
              <div
                className={
                  "loginButton funkerButton" +
                  (buttonDisabled ? " disabledButton" : "")
                }
                onClick={HandleRegistration}
              >
                REGISTER
              </div>
              <div
                className="loginButton funkerButton alternateButton"
                onClick={() => HandleLinkClick(null, "login")}
              >
                LOG IN INSTEAD
              </div>
            </div>
          </div>
          {!isEmailValid && Utilities.GenerateTooltip("#email", "invalidEmail")}
          {isPasswordValid !== null &&
            !isPasswordValid &&
            Utilities.GenerateTooltip(
              "#password",
              "invalidPassword",
              "",
              "right",
              "solid",
              passwordValidationFields
            )}
        </div>
      </>
    )
  );
}

export default Register;
